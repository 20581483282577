import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Grid, Typography, TextField, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, IconButton, FormControl, FormLabel, Snackbar, SnackbarContent, Tooltip } from '@mui/material';
import { Close, ArrowBack, Delete, CheckCircle, Cancel } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment-timezone';

const BulkScheduleDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [cycleModalOpen, setCycleModalOpen] = useState(false);
  const [eventLogsModalOpen, setEventLogsModalOpen] = useState(false);
  const [processNowModalOpen, setProcessNowModalOpen] = useState(false);
  const [processingHistory, setProcessingHistory] = useState([]);
  const [eventLogs, setEventLogs] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });
  const [customerOrders, setCustomerOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [productQuantities, setProductQuantities] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);


  const fetchProcessingHistory = async () => {
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      const response = await axios.get(`https://api.qpilot.cloud/Sites/1779/ScheduledOrders/${id}/ProcessingCycles`, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`
        }
      });
      setProcessingHistory(response.data.items);
    } catch (error) {
      console.error('Failed to fetch processing history', error);
    }
  };

  const fetchEventLogs = async () => {
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      const response = await axios.get(`https://api.qpilot.cloud/api/ScheduledOrders/${id}/EventLogs`, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`
        }
      });
      setEventLogs(response.data);
    } catch (error) {
      console.error('Failed to fetch event logs', error);
    }
  };

  const fetchSchedule = async () => {
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      const response = await axios.get(`https://api.qpilot.cloud/Sites/1779/ScheduledOrders/${id}`, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`
        }
      });
      setSchedule(response.data);
      await fetchProcessingHistory();
      await fetchEventLogs();
    } catch (error) {
      setError('Failed to fetch schedule');
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerOrders = async (customerId) => {
    try {
      setOrdersLoading(true);
      const pfresh_token = sessionStorage.getItem('pfresh_bulk_jwt_token');
      const response = await axios.get(`https://order.petfresh.com.au/wp-json/wc/v3/orders?customer=${customerId}`, {
        headers: {
          'Authorization': `Bearer ${pfresh_token}`
        }
      });
      setCustomerOrders(response.data);
    } catch (error) {
      console.error('Failed to fetch customer orders', error);
    } finally {
      setOrdersLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, [id]);

  const fetchProducts = async (query) => {
    setLoadingProducts(true);
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      const response = await axios.get(`https://api.qpilot.cloud/Sites/1779/Products?search=${query}`, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`
        }
      });
      setProducts(response.data.items);
    } catch (error) {
      console.error('Failed to fetch products', error);
    } finally {
      setLoadingProducts(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFrequencyChange = (event) => {
    setSchedule({ ...schedule, frequency: event.target.value });
    setIsChanged(true);
  };

  const handleStoreChange = (event) => {
    setSchedule({ ...schedule, metadata: { ...schedule.metadata, collection_store: event.target.value } });
    setIsChanged(true);
  };

  const handleFieldChange = (field, value) => {
    setSchedule({ ...schedule, [field]: value });
    setIsChanged(true);
  };

  const handleCustomerFieldChange = (field, value) => {
    setSchedule({ ...schedule, customer: { ...schedule.customer, [field]: value } });
    setIsChanged(true);
  };

  const handleSearchSubmit = () => {
    fetchProducts(searchQuery);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    fetchProducts('');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setProductQuantities({}); // Reset quantities
    setSearchQuery(''); // Clear search query
    setProducts([]); // Clear product list
  };  

  const handleAddProduct = async (product) => {
    const quantity = productQuantities[product.id] || 1; // Get the quantity from state or default to 1
    if (isChanged) {
      const confirmation = window.confirm("There are unsaved changes on the scheduled order. Would you like to save these changes before adding this item?");
      
      // If the user clicks "Cancel", return early and do not add the product
      if (!confirmation) {
        return; // Exit early if user cancels
      }
  
      // Save changes if the user clicks "OK"
      await handleSaveChanges();
    }
  
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
  
      const payload = {
        scheduledOrderItems: [
          {
            productId: product.id.toString(),
            quantity: quantity // Pass the correct quantity here
          }
        ],
        scheduledOrderId: schedule.id
      };
  
      await axios.post(`https://api.qpilot.cloud/Sites/1779/ScheduledOrderItems`, payload, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`,
          'Content-Type': 'application/json',
        }
      });
  
      setSnackbar({ open: true, message: 'Product added successfully', type: 'success' });
      fetchSchedule(); // Refresh schedule after adding product
    } catch (error) {
      setSnackbar({ open: true, message: `Failed to add product: ${error.message}`, type: 'error' });
    } finally {
      handleCloseModal();
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      await axios.delete(`https://api.qpilot.cloud/Sites/1779/ScheduledOrderItems/${productToDelete.id}`, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`,
        },
      });
      
      setSnackbar({ open: true, message: 'Product deleted successfully', type: 'success' });
      fetchSchedule();  // Refresh the schedule after deletion
      setDeleteModalOpen(false);
    } catch (error) {
      setSnackbar({ open: true, message: `Failed to delete product: ${error.message}`, type: 'error' });
    }
  };

  const handleOpenDeleteModal = (product) => {
    setProductToDelete(product);
    setDeleteModalOpen(true);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedItems = [...schedule.scheduledOrderItems];
    updatedItems[index].quantity = quantity;
    setSchedule({ ...schedule, scheduledOrderItems: updatedItems });
    setIsChanged(true);
  };
  
  const handleModalQuantityChange = (productId, quantity) => {
    setProductQuantities({ ...productQuantities, [productId]: quantity });
  };  
  

  const handleMaxCyclesChange = (index, maxCycles) => {
    const updatedItems = [...schedule.scheduledOrderItems];
    updatedItems[index].maxCycles = maxCycles;
    setSchedule({ ...schedule, scheduledOrderItems: updatedItems });
    setIsChanged(true);
  };

  const handleProcessNow = async () => {
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      await axios.post(`https://api.qpilot.cloud/Sites/1779/ScheduledOrders/${id}/Retry`, {}, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`
        }
      });
      setProcessNowModalOpen(false);
      setSnackbar({ open: true, message: 'Processing initiated', type: 'success' });
      await fetchProcessingHistory();
    } catch (error) {
      setSnackbar({ open: true, message: `Processing failed: ${error.message}`, type: 'error' });
    }
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;  // The date selected by the user (YYYY-MM-DD)
    
    // Convert the date to ISO format and set the time to "00:00:00" in UTC
    const isoFormattedDate = moment.utc(selectedDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).toISOString();
    
    // Update the schedule with the correctly formatted next occurrence date
    setSchedule({ ...schedule, nextOccurrenceUtc: isoFormattedDate });
    setIsChanged(true);
  };

  const handleHistoryModalOpen = () => {
    fetchCustomerOrders(schedule.customer.id);
    setHistoryModalOpen(true);
  };
  
  const handleHistoryModalClose = () => setHistoryModalOpen(false);
  const handleCycleModalOpen = () => setCycleModalOpen(true);
  const handleCycleModalClose = () => setCycleModalOpen(false);
  const handleEventLogsModalOpen = () => setEventLogsModalOpen(true);
  const handleEventLogsModalClose = () => setEventLogsModalOpen(false);

  const handleSaveChanges = async () => {
    setSnackbar({ open: true, message: 'Updating Schedule...', type: 'info' });

    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      const pfresh_token = sessionStorage.getItem('pfresh_bulk_jwt_token');

      await axios.put(`https://api.qpilot.cloud/Sites/1779/ScheduledOrders/${id}`, schedule, {
        headers: {
          'Authorization': `Bearer ${qpilot_token}`,
          'Content-Type': 'application/json',
        }
      });

      await axios.post('https://order.petfresh.com.au/wp-json/wc/v3/update-user-data/', {
        user_id: schedule.customer.id,
        first_name: schedule.customer.firstName,
        last_name: schedule.customer.lastName,
        email: schedule.customer.email,
        phone: schedule.customer.phoneNumber,
      }, {
        headers: {
          'Authorization': `Bearer ${pfresh_token}`,
          'Content-Type': 'application/json',
        }
      });

      setSnackbar({ open: true, message: 'User Updated in Qpilot\nUser Updated in Pet Fresh System', type: 'success' });
      setIsChanged(false); // Remove save button once the order has been updated
      await fetchSchedule();
    } catch (error) {
      console.error('Update Failed:', error);
      setSnackbar({ open: true, message: `Update Failed: ${error.message}`, type: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', type: '' });
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '20px' }}>
        {/* Back Button */}
        <Grid item>
          <Button
            style={{ background: "#9ba03b", color: "white" }}
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
            size="small"  // Make the button smaller
          >
            Back
          </Button>
        </Grid>

        {/* Edit Bulk Schedule Heading */}
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h6">Edit Bulk Schedule #{schedule.id}</Typography>
        </Grid>

        {/* Action Buttons */}
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleHistoryModalOpen} size="small">
                Customer Order History
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleCycleModalOpen} size="small">
                Cycle History
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleEventLogsModalOpen} size="small">
                Event Logs
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setProcessNowModalOpen(true)} size="small">
                Process Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Scheduled Order Details</Typography>
          <FormControl fullWidth margin="normal">
            <FormLabel>Status</FormLabel>
            <Select value={schedule.status} fullWidth onChange={(e) => handleFieldChange('status', e.target.value)}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Paused">Paused</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Next Occurrence (UTC)"
            type="date"
            value={moment(schedule.nextOccurrenceUtc).format('YYYY-MM-DD')}  // Displaying in "YYYY-MM-DD" format
            onChange={handleDateChange}  // Use the new handler
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            min={moment().format('YYYY-MM-DD')}
          />
          <TextField
            label="Cycles"
            value={schedule.cycles}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">Occurrences</InputAdornment>,
            }}
          />
          <TextField
            label="Frequency"
            type="number"
            value={schedule.frequency}
            onChange={handleFrequencyChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">Weeks</InputAdornment>,
              inputProps: { min: 1 }
            }}
          />
          <FormControl fullWidth margin="normal">
            <FormLabel>Store</FormLabel>
            <Select value={schedule.metadata.collection_store || 'None Selected'} onChange={handleStoreChange} fullWidth>
              <MenuItem value="Bellevue Store">Bellevue Store</MenuItem>
              <MenuItem value="Bayswater Store">Bayswater Store</MenuItem>
              <MenuItem value="Maddington Store">Maddington Store</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h6" style={{ marginTop: '20px' }}>Customer Details</Typography>
          <TextField
            label="First Name"
            value={schedule.customer.firstName}
            onChange={(e) => handleCustomerFieldChange('firstName', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            value={schedule.customer.lastName}
            onChange={(e) => handleCustomerFieldChange('lastName', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email Address"
            value={schedule.customer.email}
            onChange={(e) => handleCustomerFieldChange('email', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone Number"
            value={schedule.customer.phoneNumber}
            onChange={(e) => handleCustomerFieldChange('phoneNumber', e.target.value)}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" style={{ marginTop: '20px' }}>Payment Details</Typography>
          <TextField
            label="Order Total"
            value={`$${schedule.total}`}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Payment Method"
            value={schedule.paymentMethod?.description || 'None'}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" style={{ marginTop: '20px' }}>Order Notes</Typography>
          <TextField
            label="Order Notes"
            value={schedule.note || ''}
            onChange={(e) => handleFieldChange('note', e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6">Products</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Sale Price</TableCell>
                  <TableCell>
                    <Tooltip title="# of times this should process. Set to 1 for next order only">
                      <span>Max Cycles</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedule.scheduledOrderItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.product.title}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(index, Number(e.target.value))}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={item.price || ''} fullWidth 
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={item.salePrice || ''} fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.maxCycles || ''}
                        onChange={(e) => handleMaxCyclesChange(index, Number(e.target.value))}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                    <IconButton color="secondary" onClick={() => handleOpenDeleteModal(item)}>
                      <Delete />
                    </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }} onClick={handleOpenModal}>ADD A PRODUCT</Button>
        </Grid>
      </Grid>

      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>
          Are you sure you want to delete {productToDelete?.product.title} from schedule order #{schedule.id}?
        </DialogTitle>
        <DialogActions>
          <Button 
            onClick={() => setDeleteModalOpen(false)} 
            color="secondary"
            sx={{ backgroundColor: 'grey', color: 'white' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteProduct} 
            color="primary" 
            sx={{ backgroundColor: '#9ba03b', color: 'white' }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>
          Add Products
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Search Products"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleSearchSubmit}>Search</Button>
                </InputAdornment>
              )
            }}
          />
          {loadingProducts ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress />
            </div>
          ) : products.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell><img src={product.imageUrl} alt={product.title} style={{ width: 50 }} /></TableCell>
                    <TableCell>{product.title}</TableCell>
                    <TableCell dangerouslySetInnerHTML={{ __html: product.description }} />
                    <TableCell>{product.price}</TableCell>
                    <TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={productQuantities[product.id] || 1} // Get the quantity from state or default to 1
                        onChange={(e) => handleModalQuantityChange(product.id, Number(e.target.value))}
                        style={{ width: 80 }}
                      />
                    </TableCell>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleAddProduct(product)}>
                        Add to Schedule
                      </Button>                    
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          ) : (
            <Typography>No products found</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Order History Modal */}
      <Dialog open={historyModalOpen} onClose={handleHistoryModalClose} fullWidth maxWidth="md">
        <DialogTitle>
          {`${schedule.customer.firstName} ${schedule.customer.lastName}'s Bulk Order History`}
          <IconButton
            aria-label="close"
            onClick={handleHistoryModalClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {ordersLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order #</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Items</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>Scheduled?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerOrders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>#{order.id}</TableCell>
                      <TableCell>
                        {moment(order.date_created).format('ddd, DD/MM/YY')}<br />
                        <small>{moment(order.date_created).fromNow()}</small>
                      </TableCell>
                      <TableCell>
                        {order.status === 'prod-scheduled' ? 'Awaiting Production' :
                          order.status === 'pending-fulfilment' ? 'Not Actioned Yet' :
                          order.status === 'ready-to-collect' ? 'Ready to Collect' :
                          order.status === 'waiting-for-store-transfer' ? 'Pending Transfer to Store' :
                          order.status === 'pickup' ? 'Awaiting Action' :
                          order.status === 'packed' ? 'Ready to Collect' :
                          order.status === 'click-collect-esc' ? 'Escalated to Warehouse' :
                          order.status === 'store-transfer' ? 'Awaiting Store Transfer' :
                          order.status === 'ready-collect' ? 'Ready to Collect' :
                          order.status}
                      </TableCell>
                      <TableCell>
                        {order.line_items.map((item) => (
                          <div key={item.id}>
                            {item.name}
                            {item.meta_data.some(meta => meta.key === 'Status') && (
                              <Tooltip title={item.meta_data.find(meta => meta.key === 'Status')?.value || ''}>
                                <CheckCircle sx={{ color: 'green', fontSize: '1rem' }} />
                              </Tooltip>
                            )}
                            <br />
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>{order.customer_note || 'N/A'}</TableCell>
                      <TableCell>
                        {order.meta_data.some(meta => meta.key === 'Scheduled Order Id') ? (
                          <Tooltip title={`Scheduled Order Id: ${order.meta_data.find(meta => meta.key === 'Scheduled Order Id').value}`}>
                            <a href={`https://wms.neicha.com.au/bulk-schedules/${order.meta_data.find(meta => meta.key === 'Scheduled Order Id').value}`}>
                              <CheckCircle sx={{ color: 'green', fontSize: '1rem' }} />
                            </a>
                          </Tooltip>
                        ) : (
                          <Cancel sx={{ color: 'red', fontSize: '1rem' }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHistoryModalClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cycle History Modal */}
      <Dialog open={cycleModalOpen} onClose={handleCycleModalClose} fullWidth maxWidth="md">
        <DialogTitle>
          Cycle History
          <IconButton
            aria-label="close"
            onClick={handleCycleModalClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cycle ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processingHistory.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.processingId}</TableCell>
                  <TableCell>{moment(item.startDateUtc).tz('Australia/Perth').format('DD/MM/YY hh:mm A')}</TableCell>
                  <TableCell>
                    <Tooltip title={item.scheduledOrderFailureReason || ''}>
                      <span>{item.status}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item.chargedTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCycleModalClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Event Logs Modal */}
      <Dialog open={eventLogsModalOpen} onClose={handleEventLogsModalClose} fullWidth maxWidth="md">
        <DialogTitle>
          Event Logs
          <IconButton
            aria-label="close"
            onClick={handleEventLogsModalClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventLogs.map((log) => (
                <TableRow key={log.Id}>
                  <TableCell>{moment(log.EventUtc).tz('Australia/Perth').format('DD/MM/YY hh:mm A')}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html: log.DescriptionFormatted }} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEventLogsModalClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>

      {isChanged && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        >
          Save Changes
        </Button>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <SnackbarContent
          message={
            snackbar.type === 'info' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} style={{ marginRight: 10 }} />
                {snackbar.message}
              </div>
            ) : (
              snackbar.message
            )
          }
          style={{
            backgroundColor: snackbar.type === 'success' ? 'green' : snackbar.type === 'error' ? 'red' : 'blue',
          }}
        />
      </Snackbar>

      {/* Process Now Confirmation Dialog */}
      <Dialog open={processNowModalOpen} onClose={() => setProcessNowModalOpen(false)}>
        <DialogTitle>Confirm Process Now</DialogTitle>
        <DialogContent>Are you sure you want to process this schedule now?</DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setProcessNowModalOpen(false)} 
            color="secondary"
            sx={{ backgroundColor: 'grey', color: 'white' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleProcessNow} 
            color="primary" 
            sx={{ backgroundColor: '#9ba03b', color: 'white' }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkScheduleDetails;