import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography, Paper, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Accordion, AccordionSummary, AccordionDetails, InputAdornment, Tooltip, Drawer
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import BoltIcon from '@mui/icons-material/Bolt';
import SellIcon from '@mui/icons-material/Sell';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

dayjs.extend(weekday);
dayjs.extend(isBetween);

const CreateStockRequest = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [priority, setPriority] = useState('Low');
  const [requestedBy, setRequestedBy] = useState('');
  const [selectedStore, setSelectedStore] = useState(JSON.parse(localStorage.getItem('selectedStore')) || '');
  const [requestNotes, setRequestNotes] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forecastData, setForecastData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [runningForecast, setRunningForecast] = useState(false);
  const [forecastStatus, setForecastStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [excludedDates, setExcludedDates] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [orderSearchQuery, setOrderSearchQuery] = useState('');
  const [totalOrderedItems, setTotalOrderedItems] = useState(0);

  useEffect(() => {
    if (user && user.name) {
      setRequestedBy(user.name);
    }
    fetchStockData();
  }, [user]);

  const fetchStockData = async () => {
    try {
      setLoading(true);
      const storeId = selectedStore.id;
      const accessToken = sessionStorage.getItem('wms_access_token');
      const response = await axios.get(`https://wms-api.neicha.com.au/all-store-stock-statuses?store_id=${storeId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setTableData(formatStockData(response.data));
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatStockData = (data) => {
    return Object.keys(data.departments).map((department) => ({
      category: department,
      products: Object.keys(data.departments[department]).map((sku) => ({
        sku,
        product: data.departments[department][sku].productname,
        price: (data.departments[department][sku].productprice / 100).toFixed(2),
        stock: data.departments[department][sku].stocklevel,
        quantity: 0,
        recommendedReorder: 0,
        forecasted: null // default to null if no forecasted data
      }))
    }));
  };

  const handleQuantityChange = useCallback((categoryIndex, productIndex, value) => {
    setTableData((prevData) => {
      // Check if categoryIndex and productIndex are valid
      if (
        categoryIndex < 0 || 
        categoryIndex >= prevData.length || 
        productIndex < 0 || 
        productIndex >= prevData[categoryIndex].products.length
      ) {
        console.warn('Invalid categoryIndex or productIndex', categoryIndex, productIndex);
        return prevData; // return the previous state if indexes are invalid
      }
  
      const updatedData = [...prevData];
      const updatedProducts = [...updatedData[categoryIndex].products];
      
      // Update the quantity safely
      updatedProducts[productIndex].quantity = Math.max(0, value);
      updatedData[categoryIndex].products = updatedProducts;
  
      updateTotalOrderedItems(updatedData);
      return updatedData;
    });
  }, []);
  
  const handleQuantityChangeInSummary = useCallback((sku, value) => {
    setTableData((prevData) => {
      const updatedData = prevData.map(category => ({
        ...category,
        products: category.products.map(product =>
          product.sku === sku ? { ...product, quantity: Math.max(0, value) } : product
        )
      }));
      updateTotalOrderedItems(updatedData);
      return updatedData;
    });
  }, []);

  const updateTotalOrderedItems = (data) => {
    const total = data.reduce((acc, category) => {
      return acc + category.products.reduce((productAcc, product) => {
        return productAcc + (product.quantity > 0 ? 1 : 0);
      }, 0);
    }, 0);
  
    setTotalOrderedItems(total);
  };

  const handleSave = async () => {
    const accessToken = sessionStorage.getItem('wms_access_token');

    const items = tableData.flatMap(category =>
      category.products
        .filter(product => product.quantity > 0)
        .map(product => ({
          sku: product.sku,
          qtyrequested: product.quantity,
          qtytransferred: 0
        }))
    );

    const requestBody = {
      dateplaced: new Date().toISOString(),
      storeid: selectedStore.id,
      createdBy: requestedBy,
      notes: requestNotes,
      status: "Pending",
      datefulfilled: null,
      priority: priority === "Low" ? 1 : priority === "Medium" ? 2 : 3,
      items: items
    };

    try {
      const response = await axios.post('https://wms-api.neicha.com.au/stock-requests/', requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const newRequestId = response.data.id;
      navigate(`/view-request/${newRequestId}`, { state: { scrollToTop: true } }); // Navigate to the newly created stock request
    } catch (error) {
      console.error('Error saving stock request:', error);
      // Handle error accordingly (e.g., show an alert or notification)
    }
  };

  const getEstimatedNextRequestDate = () => {
    let date = dayjs();
    let daysToAdd = 0;
    while (daysToAdd < 2) {
      date = date.add(1, 'day');
      if (date.weekday() !== 0 && date.weekday() !== 6) {
        daysToAdd++;
      }
    }
    return date.format('YYYY-MM-DD');
  };

  const handleRunForecast = async () => {
    try {
      setRunningForecast(true);
      setForecastStatus('QUEUED');
      setPercentage(0); // Reset percentage when starting a new forecast
      const storeId = selectedStore.id;
      const accessToken = sessionStorage.getItem('wms_access_token');
      const response = await axios.get(`https://wms-api.neicha.com.au/forecast?location_id=${storeId}&start_date=${startDate}&end_date=${endDate}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const taskId = response.data.task_id;
      pollForecastStatus(taskId);
    } catch (error) {
      console.error('Error running forecast:', error);
      setRunningForecast(false);
    }
  };

  const pollForecastStatus = async (taskId) => {
    const poll = async () => {
      try {
        const response = await axios.get(`https://wms-api.neicha.com.au/forecast/status/${taskId}`);
        const { state, processed, total, result } = response.data;

        if (state === 'PENDING') {
          setForecastStatus('QUEUED');
        } else if (state === 'PROGRESS') {
          const newPercentage = Math.round((processed / total) * 100);
          setPercentage(newPercentage);
          setForecastStatus(`IN PROGRESS ${newPercentage}%`);
        } else if (state === 'SUCCESS') {
          setForecastStatus('');
          const formattedData = formatForecastData(result);
          setForecastData(formattedData);
          updateTableWithForecast(formattedData);
          setRunningForecast(false);
          setForecastStatus('Forecast Generated');
          setTimeout(() => setForecastStatus('Run Forecast'), 5000);
          return;
        }

        setTimeout(poll, 2500);
      } catch (error) {
        console.error('Error polling forecast status:', error);
        setRunningForecast(false);
      }
    };

    poll();
  };

  const formatForecastData = (data) => {
    const forecastMap = {};
    Object.keys(data).forEach(date => {
        data[date].forEach(item => {
            const { sku, "Projected Sales": sales, productName, source } = item;
            if (!forecastMap[sku]) {
                forecastMap[sku] = { productName, dates: {}, total: 0, source: {} };
            }
            forecastMap[sku].dates[date] = sales;
            forecastMap[sku].total += sales;
            forecastMap[sku].source[date] = source;
        });
    });
    return Object.keys(forecastMap).map((sku) => ({
        sku,
        productName: forecastMap[sku].productName,
        dates: forecastMap[sku].dates,
        total: forecastMap[sku].total,
        source: forecastMap[sku].source
    }));
  };

  const updateTableWithForecast = (forecastData) => {
    setTableData((prevData) => {
      const updatedData = [...prevData];
      forecastData.forEach((forecastItem) => {
        updatedData.forEach((category) => {
          category.products.forEach((product) => {
            if (product.sku === forecastItem.sku) {
              product.forecasted = forecastItem.total;
              product.recommendedReorder = Math.max(forecastItem.total + Math.round(forecastItem.total * 0.1) - product.stock, 0);
            }
          });
        });
      });
      return updatedData;
    });
  };

  const handleAccordionChange = (isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      setAccordionExpanded(true);
    }
  };

  const filteredForecastData = useMemo(() => forecastData.filter(item =>
    item.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.productName.toLowerCase().includes(searchQuery.toLowerCase())
  ), [forecastData, searchQuery]);

  const filteredOrderData = useMemo(() => tableData.flatMap(category =>
    category.products.filter(product =>
      product.product.toLowerCase().includes(orderSearchQuery.toLowerCase())
    )
  ), [tableData, orderSearchQuery]);

  const handleExcludeDate = (date) => {
    setExcludedDates((prev) => {
      const updated = prev.includes(date) ? prev.filter(d => d !== date) : [...prev, date];
      return updated;
    });
  
    const newForecastData = forecastData.map(item => {
      const newItem = { ...item, dates: { ...item.dates } };
  
      if (excludedDates.includes(date)) {
        newItem.total += newItem.dates[date];
      } else {
        newItem.total -= newItem.dates[date];
      }
  
      newItem.dates[date] = newItem.dates[date];
      return newItem;
    });
  
    setForecastData(newForecastData);
    updateTableWithForecast(newForecastData);
  };

  const handlePrint = () => {
    const orderedItems = tableData.flatMap(category =>
      category.products
        .filter(product => product.quantity > 0)
        .map(product => `
          <tr style="height: 23px;">
            <td style="height: 23px;">${product.product}</td>
            <td style="height: 23px;">${product.stock}</td>
            <td style="height: 23px;">${product.quantity}</td>
            <td style="height: 23px;">&nbsp;</td>
          </tr>
        `)
    ).join('');
  
    const printContent = `
      <table style="font-family: Times New Roman; width: 100%;" border="1 px black" cellpadding="10px">
        <tbody>
          <tr style="height: 23px;">
            <td style="height: 23px;" colspan="3">
              <h2>STOCK REQUEST</h2>
            </td>
            <td style="height: 23px;" colspan="1">
              <h2 style="text-align: right;">REQ #</h2>
            </td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;">&nbsp;<strong>REQUEST DATE</strong></td>
            <td style="height: 23px;">${dayjs().format('YYYY-MM-DD')}&nbsp;</td>
            <td style="height: 23px;">&nbsp;<strong>REQUESTED BY</strong></td>
            <td style="height: 23px;">${requestedBy}&nbsp;</td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;">&nbsp;<strong>STORE</strong></td>
            <td style="height: 23px;">&nbsp;${selectedStore.storename.replace(/ - Western Australia|\s*\([^)]*\)|\s*Store/g, '')}</td>
            <td style="height: 23px;">&nbsp;<strong>PRIORITY</strong></td>
            <td style="height: 23px;">&nbsp;${priority}</td>
          </tr>
          <tr style="height: 23px;">
            <td colspan="4">&nbsp;<strong>REQUEST NOTES</strong></td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23.5px;" colspan="4">&nbsp;${requestNotes}</td>
          </tr>
          <tr style="background-color: #f5f5f5; height: 35px;">
            <td style="height: 23px;" colspan="4"><strong>REQUEST ITEMS</strong></td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;"><strong>ITEM</strong></td>
            <td style="height: 23px;"><strong>CURRENT STOCK</strong></td>
            <td style="height: 23px;"><strong>QTY REQ</strong></td>
            <td style="height: 23px;">&nbsp;<strong>QTY SENT</strong></td>
          </tr>
          ${orderedItems}
          <tr style="background-color: #f5f5f5; height: 35px;">
            <td style="height: 23px;" colspan="4"><strong>WAREHOUSE USAGE ONLY</strong></td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Date Packed</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Date Sent</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Packed By</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 150px;">
            <td colspan="1"><strong>Warehouse Remarks</strong></td>
            <td colspan="3">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    `;
    const printWindow = window.open('', '', 'height=800,width=600');
    printWindow.document.write('<html><head><title>Stock Request</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handleRemoveItem = (sku) => {
    handleQuantityChangeInSummary(sku, 0);
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <IconButton onClick={() => setOpen(true)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ display: 'inline', marginLeft: '8px' }}>
            Back to Stock Requests
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            style={{ float: 'right', marginTop: '-8px' }}
          >
            <PrintIcon /> Print
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h4">Create New Stock Request</Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Request Date"
            type="date"
            defaultValue={dayjs().format('YYYY-MM-DD')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={runningForecast}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Priority"
            select
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            fullWidth
            disabled={runningForecast}
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            label="Requested By"
            value={requestedBy}
            onChange={(e) => setRequestedBy(e.target.value)}
            fullWidth
            disabled={runningForecast}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Store"
            value={selectedStore.storename.replace(/ - Western Australia|\s*\([^)]*\)|\s*Store/g, '')}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            disabled={runningForecast}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Estimated Next Request"
            type="date"
            value={getEstimatedNextRequestDate()}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled={runningForecast}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Request Notes"
            value={requestNotes}
            onChange={(e) => setRequestNotes(e.target.value)}
            multiline
            rows={4}
            fullWidth
            disabled={runningForecast}
          />
        </Grid>
        <Grid item>
          <Paper style={{ padding: '1em', background: '#f9f9f9', borderRadius: '10px', border: '0.5px solid grey' }}>
            <Grid container direction="column" spacing={2}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                  <AutoAwesomeIcon style={{ marginRight: 8 }} /> Run Forecast
                </Typography>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      const endDateInput = document.getElementById('end-date');
                      endDateInput.focus();
                      endDateInput.click();
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: dayjs().format('YYYY-MM-DD') }}
                    style={{ background: 'white', marginRight: 16 }}
                    disabled={runningForecast}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    id="end-date"
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: startDate }}
                    style={{ background: 'white', marginRight: 16 }}
                    disabled={runningForecast}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRunForecast}
                    disabled={runningForecast}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {runningForecast ? (
                      <>
                        <CircularProgress size={24} style={{ marginRight: 8 }} />
                        <span>{percentage === 0 ? 'QUEUED' : `${percentage}%`}</span>
                      </>
                    ) : forecastStatus || 'Run Forecast'}
                  </Button>
                </Grid>
                {forecastData.length > 0 && (
                  <Grid item style={{ width: '100%', marginTop: '1em' }}>
                    <Accordion expanded={accordionExpanded} onChange={(e, isExpanded) => handleAccordionChange(isExpanded)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                          <DoneIcon style={{ marginRight: 8 }} /> Forecast Generated at {dayjs().format('hh:mm A')}
                        </Typography>
                        <TextField
                          label="Search"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          style={{ marginLeft: 'auto', width: '30%', padding: '0.5em' }}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>SKU</TableCell>
                                <TableCell>Product</TableCell>
                                {Object.keys(forecastData[0].dates).map(date => (
                                  <TableCell
                                    key={date}
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleExcludeDate(date)}
                                    onMouseEnter={() => {
                                      Array.from(document.querySelectorAll(`.date-${date}`)).forEach(cell => {
                                        cell.style.textDecoration = 'line-through';
                                        cell.style.color = 'red';
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      Array.from(document.querySelectorAll(`.date-${date}`)).forEach(cell => {
                                        cell.style.textDecoration = excludedDates.includes(date) ? 'line-through' : 'none';
                                        cell.style.color = excludedDates.includes(date) ? 'red' : 'inherit';
                                      });
                                    }}                                    
                                  >
                                    {dayjs(date).format('ddd, DD/MM')}
                                  </TableCell>
                                ))}
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredForecastData.map(row => (
                                <TableRow key={row.sku}>
                                  <TableCell>{row.sku}</TableCell>
                                  <TableCell>{row.productName}</TableCell>
                                  {Object.keys(row.dates).map(date => (
                                    <TableCell
                                      key={date}
                                      className={`date-${date}`}
                                      style={{
                                        textDecoration: excludedDates.includes(date) ? 'line-through' : 'none',
                                        color: excludedDates.includes(date) ? 'red' : 'inherit',
                                      }}
                                    >
                                      {row.dates[date] !== undefined && row.dates[date] !== null ? row.dates[date] : 0} {row.source[date] && row.source[date]}
                                    </TableCell>
                                  ))}
                                  <TableCell>{row.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item container justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<BoltIcon />}
            style={{ marginRight: '8px' }}
            onClick={() => {
              tableData.forEach((categoryData, categoryIndex) => {
                categoryData.products.forEach((product, productIndex) => {
                  if (product.recommendedReorder > 0) {
                    handleQuantityChange(categoryIndex, productIndex, product.recommendedReorder);
                  }
                });
              });
            }}
            disabled={!forecastData.length || runningForecast} // Disable button if forecast not run or running
          >
            Auto-fill from Forecast
          </Button>
          <TextField
            label="Search Order Table"
            value={orderSearchQuery}
            onChange={(e) => setOrderSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ marginLeft: 'auto', width: '30%' }}
          />
        </Grid>
        <Grid item style={{ overflowY: 'auto', flexGrow: 1 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer style={{ overflowX: "initial" }} component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Current Stock</TableCell>
                    <TableCell>Forecasted</TableCell>
                    <TableCell>Recommended</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((categoryData, categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                      <TableRow>
                        <TableCell colSpan={8} style={{ backgroundColor: '#f0f0f0' }}>
                          <Typography variant="subtitle1">{categoryData.category}</Typography>
                        </TableCell>
                      </TableRow>
                      {categoryData.products.map((row, productIndex) => (
                        <TableRow key={row.sku} style={{ backgroundColor: row.quantity > 0 ? '#e0f7fa' : 'inherit' }}>
                          <TableCell>{categoryData.category}</TableCell>
                          <TableCell>{row.product}</TableCell>
                          <TableCell>{row.sku}</TableCell>
                          <TableCell>${row.price}</TableCell>
                          <TableCell>{row.stock}</TableCell>
                          <TableCell>{row.forecasted || ''}</TableCell>
                          <TableCell>{row.recommendedReorder || ''}</TableCell>
                          <TableCell>
                            <Grid container alignItems="center">
                              <Tooltip title="Double click to set as recommended">
                                <TextField
                                  type="number"
                                  value={row.quantity}
                                  onChange={(e) => handleQuantityChange(categoryIndex, productIndex, parseInt(e.target.value, 10))}
                                  onDoubleClick={() => handleQuantityChange(categoryIndex, productIndex, row.recommendedReorder)}
                                  inputProps={{ min: 0 }}
                                  style={{ width: '80px', textAlign: 'center' }}
                                  textAlign={'center'}
                                  InputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                    style: { MozAppearance: 'textfield' },
                                    disableUnderline: true
                                  }}
                                  sx={{
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                      WebkitAppearance: 'none',
                                      margin: 0,
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{ height: 'calc(100% - 64px)', marginTop: '64px', width: '40vw' }}
        PaperProps={{
          style: {
            height: 'calc(100% - 64px)',
            marginTop: '64px',
            width: '50vw',
            overflowY: 'auto',
          }
        }}
      >
        <div style={{ padding: '16px' }}>
          <Grid style={{marginBottom: "1em"}} container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Order Summary</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              CREATE REQUEST
            </Button>
          </Grid>
          <TableContainer style={{width:"100%"}} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Order Qty</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.flatMap(category =>
                  category.products
                    .filter(product => product.quantity > 0)
                    .map(product => (
                      <TableRow key={product.sku}>
                        <TableCell>{product.product}</TableCell>
                        <TableCell>{product.sku}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={product.quantity}
                            onChange={(e) => handleQuantityChangeInSummary(product.sku, parseInt(e.target.value, 10))}
                            inputProps={{ min: 0 }}
                            style={{ width: '80px', textAlign: 'center' }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleRemoveItem(product.sku)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Drawer>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to leave this page? Any unsaved data will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor: "lightgray", color: "black"}} onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button style={{backgroundColor: "#9ba03b", color: "white"}} onClick={() => navigate(-1)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {totalOrderedItems > 0 && (
        <Button
          variant="contained"
          color="secondary"
          style={{ position: 'fixed', bottom: '16px', right: '16px' }}
          startIcon={<SellIcon />}
          onClick={() => setDrawerOpen(true)}
        >
          View Order Summary ({totalOrderedItems} Items)
        </Button>
      )}
    </Paper>
  );
};

export default CreateStockRequest;
