import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    // Clear storage and log the user out
    localStorage.clear();
    sessionStorage.clear();
    logout({ returnTo: 'https://wms.neicha.com.au' });
  }, [logout]);

  return <div>Logging out...</div>;
};

export default Logout;