import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';  // Import the App component separately
import AuthWrapper from './AuthWrapper';  // Import the AuthWrapper component
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthWrapper>
      <App />
    </AuthWrapper>
  </React.StrictMode>
);

reportWebVitals();
