import React, { useState } from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Box } from '@mui/material';

const MostSoldItemsTable = ({ items }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Most Commonly Sold Items at This Store</Typography>
      <Box sx={{ my: 2 }}>
        <TextField
          label="Search Items"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Number Sold This Week</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Number Sold Last Week</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Number Sold 3 Weeks Ago</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Average Sold in Week</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Average Sold in Day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item) => (
              <TableRow key={item.sku}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
                <TableCell align="right">{item.lastWeek}</TableCell>
                <TableCell align="right">{item.threeWeeksAgo}</TableCell>
                <TableCell align="right">{item.avgWeekly}</TableCell>
                <TableCell align="right">{item.avgDaily}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MostSoldItemsTable;
