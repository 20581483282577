import React from 'react';
import { Fab, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CalculateIcon from '@mui/icons-material/Calculate';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileBottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const handleNavChange = (newValue) => {
    if (newValue !== currentPath) {
      navigate(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        padding: '8px 0',
      }}
    >
      <Fab
        onClick={() => handleNavChange('/mobile/home')}
        sx={{
          backgroundColor: currentPath === '/mobile/home' ? '#9ba03b' : '#fff',
          color: currentPath === '/mobile/home' ? '#fff' : '#9ba03b',
          minWidth: currentPath === '/mobile/home' ? '140px' : '56px',
          borderRadius: currentPath === '/mobile/home' ? '28px' : '50%',
          justifyContent: currentPath === '/mobile/home' ? 'flex-start' : 'center',
          paddingLeft: currentPath === '/mobile/home' ? '10px' : '0px',
          paddingRight: currentPath === '/mobile/home' ? '12px' : '0px',
          transition: 'all 0.3s ease', // Add transition for smooth animation
        }}
      >
        {currentPath === '/mobile/home' ? (
          <>
            <HomeIcon sx={{ mr: 1 }} />
            Home
          </>
        ) : (
          <HomeIcon />
        )}
      </Fab>
      <Fab
        onClick={() => handleNavChange('/mobile/stock-take')}
        sx={{
          backgroundColor: currentPath === '/mobile/stock-take' ? '#9ba03b' : '#fff',
          color: currentPath === '/mobile/stock-take' ? '#fff' : '#9ba03b',
          minWidth: currentPath === '/mobile/stock-take' ? '140px' : '56px',
          borderRadius: currentPath === '/mobile/stock-take' ? '28px' : '50%',
          justifyContent: currentPath === '/mobile/stock-take' ? 'flex-start' : 'center',
          paddingLeft: currentPath === '/mobile/stock-take' ? '10px' : '0px',
          paddingRight: currentPath === '/mobile/stock-take' ? '12px' : '0px',
          transition: 'all 0.3s ease', // Add transition for smooth animation
        }}
      >
        {currentPath === '/mobile/stock-take' ? (
          <>
            <CalculateIcon sx={{ mr: 1 }} />
            Stock Take
          </>
        ) : (
          <CalculateIcon />
        )}
      </Fab>
      <Fab
        onClick={() => handleNavChange('/mobile/search-inventory')}
        sx={{
          backgroundColor: currentPath === '/mobile/search-inventory' ? '#9ba03b' : '#fff',
          color: currentPath === '/mobile/search-inventory' ? '#fff' : '#9ba03b',
          minWidth: currentPath === '/mobile/search-inventory' ? '140px' : '56px',
          borderRadius: currentPath === '/mobile/search-inventory' ? '28px' : '50%',
          justifyContent: currentPath === '/mobile/search-inventory' ? 'flex-start' : 'center',
          paddingLeft: currentPath === '/mobile/search-inventory' ? '10px' : '0px',
          paddingRight: currentPath === '/mobile/search-inventory' ? '12px' : '0px',
          transition: 'all 0.3s ease', // Add transition for smooth animation
        }}
      >
        {currentPath === '/mobile/search-inventory' ? (
          <>
            <Inventory2Icon sx={{ mr: 1 }} />
            Inventory
          </>
        ) : (
          <Inventory2Icon />
        )}
      </Fab>
      <Fab
        onClick={() => handleNavChange('/mobile/batch-receive-bulk-orders')}
        sx={{
          backgroundColor: currentPath === '/mobile/batch-receive-bulk-orders' ? '#9ba03b' : '#fff',
          color: currentPath === '/mobile/batch-receive-bulk-orders' ? '#fff' : '#9ba03b',
          minWidth: currentPath === '/mobile/batch-receive-bulk-orders' ? '140px' : '56px',
          borderRadius: currentPath === '/mobile/batch-receive-bulk-orders' ? '28px' : '50%',
          justifyContent: currentPath === '/mobile/batch-receive-bulk-orders' ? 'flex-start' : 'center',
          paddingLeft: currentPath === '/mobile/batch-receive-bulk-orders' ? '10px' : '0px',
          paddingRight: currentPath === '/mobile/batch-receive-bulk-orders' ? '12px' : '0px',
          transition: 'all 0.3s ease', // Add transition for smooth animation
        }}
      >
        {currentPath === '/mobile/batch-receive-bulk-orders' ? (
          <>
            <QrCodeScannerIcon sx={{ mr: 1 }} />
            Batch Receive
          </>
        ) : (
          <QrCodeScannerIcon />
        )}
      </Fab>
    </Box>
  );
};

export default MobileBottomNav;