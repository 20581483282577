import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, TextField, CircularProgress, Chip, Pagination, FormControl, Select, MenuItem, FormControlLabel, Switch, Button
} from '@mui/material';
import { format, differenceInDays } from 'date-fns';

const BulkSchedules = () => {
  const [bulkSchedules, setBulkSchedules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20);
  const [storeFilter, setStoreFilter] = useState('All');
  const [showDeleted, setShowDeleted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null); // To autofocus the search field
  

  const stores = ["Bellevue Store", "Bayswater Store", "Maddington Store"];

  // Autofocus on the search field when the component loads
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('search');
    if (searchParam) {
      setSearchQuery(searchParam);
      fetchSchedules(searchParam, storeFilter, 1, showDeleted);
    } else {
      fetchSchedules('', storeFilter, 1, showDeleted);
    }
  }, [page, showDeleted, storeFilter]);

  const fetchSchedules = async (query, store, pageNumber, includeDeleted) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const qpilot_token = sessionStorage.getItem('qpilot_token');
      let url = `https://api.qpilot.cloud/Sites/1779/ScheduledOrders?page=${pageNumber}&pageSize=${pageSize}&statusNames=Processing,Success,Failed,Retry,Active,Paused,Locked,Queued`;

      if (store !== 'All') {
        url += `&metadatakey=collection_store&metadatavalue=${encodeURIComponent(store)}`;
      }

      if (includeDeleted) {
        url += ",Deleted";
      }

      if (query.trim() !== '') {
        url += `&search=${query}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${qpilot_token}`
        }
      });

      const data = await response.json();

      const formattedData = data.items.map(item => ({
        id: item.id,
        customerName: `${item.customer.firstName} ${item.customer.lastName}`,
        nextOccurrence: item.nextOccurrenceUtc ? item.nextOccurrenceUtc : 'N/A',
        lastOccurrence: item.lastOccurrenceUtc ? item.lastOccurrenceUtc : 'N/A',
        frequency: item.frequencyDisplayName || 'Unknown Frequency',
        cycles: item.cycles || 0,
        store: item.metadata?.collection_store || 'No Store Set',
        status: item.status || 'Unknown Status',
        lastProcessingCycleStatus: item.lastProcessingCycle?.status || 'No Status'
      }));

      setBulkSchedules(formattedData);
      setTotalPages(Math.ceil(data.totalCount / pageSize));
    } catch (error) {
      setErrorMessage('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date))) {
      return 'N/A';
    }
    return format(new Date(date), 'dd/MM/yy');
  };

  const getTimeDifference = (date) => {
    if (!date || isNaN(new Date(date))) {
      return 'N/A';
    }
    const now = new Date();
    const targetDate = new Date(date);
    const days = differenceInDays(targetDate, now);
    
    if (days === 0) {
      return `Today`;
    }
    
    const absDays = Math.abs(days);
    if (absDays < 7) {
      return `${absDays} days ${days > 0 ? 'from now' : 'ago'}`;
    }
    
    const weeks = Math.floor(absDays / 7);
    if (weeks < 4) {
      return `${weeks} weeks ${days > 0 ? 'from now' : 'ago'}`;
    }
    
    const months = Math.floor(absDays / 30);
    if (months < 12) {
      return `${months} months ${days > 0 ? 'from now' : 'ago'}`;
    }
    
    const years = Math.floor(absDays / 365);
    return `${years} years ${days > 0 ? 'from now' : 'ago'}`;
  };

  const handleInputBlur = () => {
    if (searchQuery.trim() !== '' || lastSearchQuery !== searchQuery) {
      setLastSearchQuery(searchQuery);
      navigate(`?search=${searchQuery}`);
      fetchSchedules(searchQuery, storeFilter, 1, showDeleted);
    } else if (searchQuery === '') {
      setLastSearchQuery('');
      navigate(`?search=`);
      fetchSchedules('', storeFilter, 1, showDeleted);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur(); // Trigger unfocus to run handleInputBlur
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'green';
      case 'Paused':
        return 'orange';
      case 'Failed':
        return 'red';
      case 'Deleted':
        return 'grey';
      default:
        return '#A020F0';
    }
  };

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
        <Typography variant="h4">Bulk Schedules</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showDeleted}
              onChange={(e) => setShowDeleted(e.target.checked)}
            />
          }
          label="Show Deleted"
        />
        <TextField
          label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onBlur={handleInputBlur}
          onKeyPress={handleSearchKeyPress}
          inputRef={searchInputRef} // Autofocus on load
          style={{ width: '40%' }}
        />
      </Grid>

      {/* Store Selection always displayed and full-width */}
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Typography>Store</Typography>
            <Select value={storeFilter} onChange={(e) => setStoreFilter(e.target.value)}>
              <MenuItem value="All">All</MenuItem>
              {stores.map(store => (
                <MenuItem key={store} value={store}>{store}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : errorMessage ? (
        <Typography variant="h6" color="error">{errorMessage}</Typography>
      ) : bulkSchedules.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: 'center', margin: '20px 0' }}>No schedules found</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Schedule Order #</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Last Occurrence</TableCell>
                  <TableCell>Next Occurrence</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Cycles</TableCell>
                  <TableCell>Store</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkSchedules.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.customerName}</TableCell>
                    <TableCell>
                      {formatDate(order.lastOccurrence)}<br/>
                      <small>{getTimeDifference(order.lastOccurrence)}</small>
                    </TableCell>
                    <TableCell>
                      {formatDate(order.nextOccurrence)}<br/>
                      <small>{getTimeDifference(order.nextOccurrence)}</small>
                    </TableCell>
                    <TableCell>{order.frequency}</TableCell>
                    <TableCell>{order.cycles}</TableCell>
                    <TableCell>{order.store}</TableCell>
                    <TableCell>
                      <Chip label={order.status} style={{ backgroundColor: getStatusColor(order.status), color: 'white' }} />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate(`/bulk-schedules/${order.id}`)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
            <Typography variant="body1">Page {page} of {totalPages}</Typography>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} />
          </Grid>
        </>
      )}
    </div>
  );
};

export default BulkSchedules;