// src/components/ViewClickCollectOrder.js

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, Paper, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { format } from 'date-fns';

const translateOrderStatus = (status) => {
  switch (status) {
    case 'prod-scheduled':
      return 'Awaiting Production';
    case 'pending-fulfilment':
      return 'Not Actioned Yet';
    case 'ready-to-collect':
      return 'Ready to Collect';
    case 'waiting-for-store-transfer':
      return 'Pending Transfer to Store';
    case 'pickup':
      return 'Awaiting Action';
    case 'packed':
      return 'Ready to Collect';
    case 'click-collect-esc':
      return 'Escalated to Warehouse';
    case 'store-transfer':
      return 'Awaiting Store Transfer';
    case 'ready-collect':
      return 'Ready to Collect';
    default:
      return status;
  }
};


const ViewClickCollectOrder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order } = state;

  const handlePrint = () => {
    window.print();
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" style={{ display: 'inline', marginLeft: '8px' }}>
            Click and Collect Order #{order.order_id}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <TextField
            label="Customer Name"
            value={`${order.first_name} ${order.last_name}`}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Order Date"
            value={order.order_date}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Status"
          value={translateOrderStatus(order.current_status)}
          fullWidth
        />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Order Notes"
            value={order.customer_note}
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Order Details</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.order_items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>${item.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Typography variant="h6">Order Total: ${order.order_items.reduce((total, item) => total + item.qty * item.price, 0)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Order History</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Update</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.order_notes.map((note, index) => (
                  <TableRow key={index}>
                    <TableCell dangerouslySetInnerHTML={{__html: note.note}}
                    ></TableCell>
                    <TableCell>{format(new Date(note.date), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                    <TableCell>{note.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <TextField
            label="Add Update"
            multiline
            rows={2}
            fullWidth
          />
          <Grid container spacing={1} alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={10}>
              <Select
                defaultValue="Internal"
                fullWidth
              >
                <MenuItem value="Internal">Send as Internal</MenuItem>
                <MenuItem value="Customer Notification">Send as Customer Notification</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" fullWidth>
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewClickCollectOrder;
