import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
  useMediaQuery, Box, IconButton, CircularProgress, Grid, Card, TableContainer,
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, FormControlLabel,
  TextField, Snackbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Barcode from 'react-barcode';

const BarcodeListen = () => {
  const [barcode, setBarcode] = useState('');
  const [type, setType] = useState('');
  const [orderTitle, setOrderTitle] = useState('');
  const [productData, setProductData] = useState(null);
  const [stockLevels, setStockLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStock, setLoadingStock] = useState(false);
  const [open, setOpen] = useState(false);
  const [focusDialogOpen, setFocusDialogOpen] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [excludeFulfilled, setExcludeFulfilled] = useState(false);
  const [qtyDialogOpen, setQtyDialogOpen] = useState(false);
  const [qtyTransferred, setQtyTransferred] = useState(0);
  const [modifiedItems, setModifiedItems] = useState({});
  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [changesCount, setChangesCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let buffer = '';

    const handleKeyPress = (e) => {
      const activeElement = document.activeElement;
      const isInputFocused = activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.contentEditable === 'true';

      // Filter out unwanted keys
      if (!isInputFocused && /^[a-zA-Z0-9]$/i.test(e.key)) {
        buffer += e.key;
      }

      if (!isInputFocused && e.key === 'Enter') {
        if (buffer) {
          setBarcode(buffer);
          determineTypeAndTitle(buffer);
          setOpen(true);
          buffer = '';
        }
      }
    };

    const handleWindowBlur = () => {
      setFocusDialogOpen(true);
    };

    const handleWindowFocus = () => {
      setFocusDialogOpen(false);
    };

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('blur', handleWindowBlur);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('blur', handleWindowBlur);
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  const determineTypeAndTitle = async (code) => {
    if (code.startsWith('PWB') || code.startsWith('BLK')) {
      setType('Bulk Order');
      const orderNumber = code.replace(/^(PWB|BLK)/, '').split('-')[0];
      setOrderTitle(`Bulk Order #${orderNumber}`);
    } else if (code.startsWith('REQ')) {
      setType('Stock Transfer Request');
      setOrderTitle(`Stock Request #${code}`);
      await fetchRequestData(code.replace(/^REQ/, '')); // Fetch request data based on ID
    } else if (code.startsWith('WHL')) {
      setType('Wholesale Order');
      setOrderTitle(`Wholesale Order #${code}`);
    } else if (isUPC(code)) {
      if (requestData) {
        handleQtyDialogOpen();
      } else {
        setType('Product Scan');
        setOrderTitle('Product'); // Set initial title
        await fetchProductData(code); // Fetch product data and update title
      }
    } else {
      setType('Web Order');
      setOrderTitle(`Web Order #${code}`);
    }
  };

  const fetchRequestData = async (id) => {
    setLoading(true);
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      const response = await axios.get(`https://wms-api.neicha.com.au/stock-request/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setRequestData(response.data);
      setOrderTitle(`Stock Request #${response.data.id}`);
    } catch (error) {
      console.error('Error fetching request data:', error);
      setRequestData(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductData = async (barcode) => {
    setLoading(true);
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      const response = await axios.get(`https://wms-api.neicha.com.au/product?barcode=${barcode}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.data.message === "Product not found.") {
        setOrderTitle('Product Doesn’t Exist in the WMS.');
        setProductData(null);
      } else {
        setProductData(response.data);
        setOrderTitle(`Product | ${response.data.productname}`); // Update title with product name
        fetchStockLevels(response.data.sku); // Fetch stock levels after fetching product data
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
      setOrderTitle('Product | Not Found');
      setProductData(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchStockLevels = async (sku) => {
    setLoadingStock(true);
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      const response = await axios.get(`https://wms-api.neicha.com.au/stock-levels/${sku}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setStockLevels(response.data.stockLevels);
    } catch (error) {
      console.error('Error fetching stock levels:', error);
      setStockLevels([]);
    } finally {
      setLoadingStock(false);
    }
  };

  const isUPC = (code) => {
    return /^[0-9]{12}$/.test(code);
  };

  const handleClose = () => {
    setOpen(false);
    setProductData(null); // Reset product data on close
    setRequestData(null); // Reset request data on close
    setStockLevels([]); // Reset stock levels on close
  };

  const handleFocusDialogClose = () => {
    setFocusDialogOpen(false);
  };

  const handleStatusChange = async () => {
    // Handle status change for the request
    const accessToken = sessionStorage.getItem('wms_access_token');
    const newStatus = requestData.status === 'Pending' ? 'Pending Transfer' : 'Fulfilled';
    try {
      await axios.post(`https://wms-api.neicha.com.au/stock-request-status`, {
        id: requestData.id,
        status: newStatus,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      // Update local request data with new status
      setRequestData(prev => ({ ...prev, status: newStatus }));
    } catch (error) {
      console.error('Error updating request status:', error);
    }
  };

  const handleDeleteRequest = async () => {
    // Handle deleting the request
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      await axios.post(`https://wms-api.neicha.com.au/stock-request-status`, {
        id: requestData.id,
        status: 'Deleted',
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      handleClose(); // Close dialog after deletion
    } catch (error) {
      console.error('Error deleting request:', error);
    }
  };

  const handleQtyChange = (sku, value) => {
    const updatedValue = Math.max(0, parseInt(value, 10)); // Ensure value is not negative
    const originalValue = flattenItems(requestData?.itemsByDepartment || {}).find(item => item.sku === sku)?.qtytransferred || 0;

    setModifiedItems(prev => ({
      ...prev,
      [sku]: updatedValue
    }));

    const inputElement = document.getElementById(`qty-input-${sku}`);
    if (updatedValue !== originalValue) {
      // Change border color to red if the value is modified
      inputElement.style.border = 'red solid';
    } else {
      // Change border color to green if the value matches the original
      inputElement.style.border = 'green solid';
    }

    // Clear previous timeout and set a new one
    if (autoSaveTimeout) clearTimeout(autoSaveTimeout);
    setChangesCount(prev => prev + 1);
    setAutoSaveTimeout(setTimeout(() => {
      sendApiRequest();
    }, 10000));

    if (changesCount >= 9) {
      sendApiRequest();
    }
  };

  const sendApiRequest = async () => {
    if (Object.keys(modifiedItems).length === 0) return;

    setSaving(true);
    setSnackbarOpen(true);
    const accessToken = sessionStorage.getItem('wms_access_token');

    const requestBody = Object.entries(modifiedItems).map(([sku, qtyTransferred]) => ({
      stockrequestid: requestData.id,
      sku,
      qtytransferred: qtyTransferred
    }));

    try {
      const response = await axios.put('https://wms-api.neicha.com.au/stock-request-transfered/', requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // Update the request data with the response from the server
      setRequestData(prev => ({
        ...prev,
        itemsByDepartment: Object.entries(prev.itemsByDepartment).reduce((acc, [dept, items]) => {
          acc[dept] = items.map(item => {
            if (item.sku in modifiedItems) {
              return { ...item, qtytransferred: modifiedItems[item.sku] };
            }
            return item;
          });
          return acc;
        }, {})
      }));

      setModifiedItems({});
      setChangesCount(0);

      // Set the input borders to green after successful sync
      Object.keys(modifiedItems).forEach(sku => {
        const inputElement = document.getElementById(`qty-input-${sku}`);
        if (inputElement) {
          inputElement.style.border = 'green solid';
        }
      });

    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setSaving(false);
      setSnackbarOpen(false);
    }
  };

  const handleQtyDialogOpen = () => {
    setQtyDialogOpen(true);
  };

  const handleQtyDialogClose = () => {
    setQtyDialogOpen(false);
  };

  const handleQtySubmit = async () => {
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      const response = await axios.put(`https://wms-api.neicha.com.au/stock-request-transfered/`, {
        stockrequestid: requestData.id,
        sku: barcode,
        qtytransferred: qtyTransferred
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setRequestData(response.data); // Update the request data with the response
    } catch (error) {
      console.error('Error updating qty transferred:', error);
    } finally {
      handleQtyDialogClose();
    }
  };

  const flattenItems = (itemsByDepartment) => {
    return Object.values(itemsByDepartment).flatMap(items => items);
  };

  const filteredItems = excludeFulfilled
    ? flattenItems(requestData?.itemsByDepartment || {}).filter(item => item.qtytransferred < item.qtyrequested)
    : flattenItems(requestData?.itemsByDepartment || {});

  const allItemsFulfilled = filteredItems.length === 0;

  const priorityText = (priority) => {
    switch (priority) {
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return 'Unknown';
    }
  };

  const statusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#fff3cd'; // Slight yellow
      case 'Deleted':
        return '#f8d7da'; // Slight red
      case 'Pending Transfer':
        return '#cce5ff'; // Slight blue
      case 'Fulfilled':
        return '#d4edda'; // Slight green
      default:
        return ''; // Default card background
    }
  };

  const priorityBackgroundColor = (priority) => {
    switch (priority) {
      case 1:
        return '#fff3cd'; // Yellow
      case 2:
        return '#ffcccb'; // Orange
      case 3:
        return '#f8d7da'; // Red
      default:
        return ''; // Default card background
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Open Dialog</Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
        maxWidth="md"
        fullWidth={!fullScreen}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px' }}>
          <Typography variant="h6" style={{ color: 'white' }}>
            {loading && !requestData ? <Skeleton width={200} /> : orderTitle}
          </Typography>
          <IconButton onClick={handleClose} style={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>
          {loading && !requestData ? (
            <CircularProgress />
          ) : requestData ? (
            <Grid container spacing={2}>
              {/* Request Details */}
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px' }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    ID
                  </Typography>
                  <Typography variant="h5" component="div">
                    {requestData.id}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px' }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Request Date
                  </Typography>
                  <Typography variant="h5" component="div">
                    {new Date(requestData.dateplaced).toLocaleDateString()}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px', backgroundColor: statusBackgroundColor(requestData.status) }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Status
                  </Typography>
                  <Typography variant="h5" component="div">
                    {requestData.status}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px' }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Store Name
                  </Typography>
                  <Typography variant="h5" component="div">
                    {requestData.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '')}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px' }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Requested By
                  </Typography>
                  <Typography variant="h5" component="div">
                    {requestData.createdBy}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ padding: '16px', backgroundColor: priorityBackgroundColor(requestData.priority) }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Priority
                  </Typography>
                  <Typography variant="h5" component="div">
                    {priorityText(requestData.priority)}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ padding: '16px' }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Notes
                  </Typography>
                  <Typography variant="h5" component="div">
                    {requestData.notes || 'None'}
                  </Typography>
                </Card>
              </Grid>

              {/* Items Table */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeFulfilled}
                      onChange={() => setExcludeFulfilled(!excludeFulfilled)}
                      color="primary"
                    />
                  }
                  label="Exclude already Fulfilled items"
                />
              </Grid>
              <Grid item xs={12}>
                {allItemsFulfilled ? (
                  <div>
                    <Typography variant="h6">
                      All items have been fulfilled. Would you like to mark this as "{requestData.status === 'Pending' ? 'Pending Transfer' : 'Completed'}"?
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={handleStatusChange}>
                      Change Status to {requestData.status === 'Pending' ? 'Pending Transfer' : 'Completed'}
                    </Button>
                  </div>
                ) : (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Qty Requested</TableCell>
                          <TableCell>Qty Fulfilled</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredItems.map((item, itemIndex) => (
                          <TableRow key={itemIndex}>
                            <TableCell>{item.sku}</TableCell>
                            <TableCell>{item.productname}</TableCell>
                            <TableCell>{item.qtyrequested}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                id={`qty-input-${item.sku}`}
                                defaultValue={item.qtytransferred}
                                onChange={(e) => handleQtyChange(item.sku, e.target.value)}
                                inputProps={{ min: 0 }} // Ensure no negative values
                                style={{ borderColor: item.inputBorderColor || '' }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h6">Barcode: {barcode}</Typography>
          )}
        </DialogContent>
        <DialogActions>
        <Button 
          style={{backgroundColor:"#9ba03b", color: "white"}} 
          color="primary" 
          onClick={() => window.location.href = `https://wms.neicha.com.au/view-request/${requestData.id}`}
        >
          View Request
        </Button>          <Button style={{backgroundColor:"#9ba03b", color: "white"}} color="secondary" onClick={handleStatusChange}>
            Mark as Fulfilled
          </Button>
          <Button onClick={handleDeleteRequest} sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            Delete Request
          </Button>
        </DialogActions>
      </Dialog>

      {/* Quantity Input Dialog */}
      <Dialog
        open={qtyDialogOpen}
        onClose={handleQtyDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>How many did you send?</DialogTitle>
        <DialogContent>
          <TextField
            type="number"
            fullWidth
            value={qtyTransferred}
            onChange={(e) => setQtyTransferred(e.target.value)}
            inputProps={{ min: 0 }} // Ensure no negative values
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQtyDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleQtySubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Focus Dialog */}
      <Dialog
        open={focusDialogOpen}
        onClose={handleFocusDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Attention Required</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Please click anywhere on this screen to resume.</Typography>
        </DialogContent>
      </Dialog>

      {/* Snackbar for auto-saving */}
      <Snackbar
        open={snackbarOpen}
        message="Auto-Saving your entry"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={<CircularProgress size={24} />}
      />
    </>
  );
};

export default BarcodeListen;
