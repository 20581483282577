import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Grid, TextField, MenuItem, FormControlLabel, Checkbox, Table, TableCell, TableContainer, TableRow, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ConfigureStore = ({ stores }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [storeInfo, setStoreInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state to manage spinner

  const { id } = useParams(); // Get the store ID from the URL parameters
  const storeId = parseInt(id, 10); // Ensure it's a number

  useEffect(() => {
    const selectedStore = stores.find(store => store.id === storeId);
    console.log('Parsed storeId:', storeId);
    console.log('Selected Store from stores array:', selectedStore);

    if (selectedStore) {
      setStoreInfo({
        ...selectedStore,
        openingHours: [
          { day: 'Monday', open: selectedStore.openinghoursmonday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghoursmonday.StartTime), closingTime: formatTime(selectedStore.openinghoursmonday.OpeningTime) },
          { day: 'Tuesday', open: selectedStore.openinghourstuesday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghourstuesday.StartTime), closingTime: formatTime(selectedStore.openinghourstuesday.OpeningTime) },
          { day: 'Wednesday', open: selectedStore.openinghourswednesday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghourswednesday.StartTime), closingTime: formatTime(selectedStore.openinghourswednesday.OpeningTime) },
          { day: 'Thursday', open: selectedStore.openinghoursthursday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghoursthursday.StartTime), closingTime: formatTime(selectedStore.openinghoursthursday.OpeningTime) },
          { day: 'Friday', open: selectedStore.openinghoursfriday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghoursfriday.StartTime), closingTime: formatTime(selectedStore.openinghoursfriday.OpeningTime) },
          { day: 'Saturday', open: selectedStore.openinghourssaturday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghourssaturday.StartTime), closingTime: formatTime(selectedStore.openinghourssaturday.OpeningTime) },
          { day: 'Sunday', open: selectedStore.openinghourssunday.StartTime !== 0, openingTime: formatTime(selectedStore.openinghourssunday.StartTime), closingTime: formatTime(selectedStore.openinghourssunday.OpeningTime) },
        ]
      });
    } else {
      console.log('No matching store found in the list of stores.');
    }
    setLoading(false); // Set loading to false after processing the store info
  }, [storeId, stores]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 100).toString().padStart(2, '0');
    const minutes = (time % 100).toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return parseInt(hours) * 100 + parseInt(minutes);
  };

  const handleStoreInfoChange = (e) => {
    const { name, value } = e.target;
    setStoreInfo({ ...storeInfo, [name]: value });
  };

  const handleOpeningHoursChange = (index, field, value) => {
    const updatedHours = [...storeInfo.openingHours];
    updatedHours[index][field] = value;
    setStoreInfo({ ...storeInfo, openingHours: updatedHours });
  };

  const handleToggleOpen = (index) => {
    const updatedHours = [...storeInfo.openingHours];
    updatedHours[index].open = !updatedHours[index].open;
    if (!updatedHours[index].open) {
      updatedHours[index].openingTime = '00:00';
      updatedHours[index].closingTime = '00:00';
    }
    setStoreInfo({ ...storeInfo, openingHours: updatedHours });
  };

  const handleSave = async () => {
    try {
      await axios.post(`https://api.neicha.com.au/stores/${storeId}`, {
        ...storeInfo,
        openinghoursmonday: { StartTime: parseTime(storeInfo.openingHours[0].openingTime), OpeningTime: parseTime(storeInfo.openingHours[0].closingTime) },
        openinghourstuesday: { StartTime: parseTime(storeInfo.openingHours[1].openingTime), OpeningTime: parseTime(storeInfo.openingHours[1].closingTime) },
        openinghourswednesday: { StartTime: parseTime(storeInfo.openingHours[2].openingTime), OpeningTime: parseTime(storeInfo.openingHours[2].closingTime) },
        openinghoursthursday: { StartTime: parseTime(storeInfo.openingHours[3].openingTime), OpeningTime: parseTime(storeInfo.openingHours[3].closingTime) },
        openinghoursfriday: { StartTime: parseTime(storeInfo.openingHours[4].openingTime), OpeningTime: parseTime(storeInfo.openingHours[4].closingTime) },
        openinghourssaturday: { StartTime: parseTime(storeInfo.openingHours[5].openingTime), OpeningTime: parseTime(storeInfo.openingHours[5].closingTime) },
        openinghourssunday: { StartTime: parseTime(storeInfo.openingHours[6].openingTime), OpeningTime: parseTime(storeInfo.openingHours[6].closingTime) },
      });
      console.log('Selected stock items saved successfully');
      setSnackbarMessage('Stock items updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving selected stock items:', error);
      setSnackbarMessage('Failed to update stock items.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Configure Stock Items
  const [stockItemsByDepartment, setStockItemsByDepartment] = useState({});
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [loadingStockItems, setLoadingStockItems] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching stock items for storeId:', storeId);
      const fetchedStockItemsByDepartment = await fetchStockItems(storeId);
      const fetchedSelectedSkus = await fetchSelectedSkus(storeId);
      setStockItemsByDepartment(fetchedStockItemsByDepartment);
      setSelectedSkus(fetchedSelectedSkus);
      setLoadingStockItems(false);
    };

    fetchData();
  }, [storeId]);

  const fetchStockItems = async (storeId) => {
    try {
      const access_token = sessionStorage.getItem('wms_access_token');
      const response = await axios.get(`https://wms-api.neicha.com.au/stock-items`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching stock items:', error);
      return {};
    }
  };

  const fetchSelectedSkus = async (storeId) => {
    try {
      const access_token = sessionStorage.getItem('wms_access_token');
      const response = await axios.get(`https://wms-api.neicha.com.au/store-stock-items/${storeId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      return response.data.map(item => item.sku);
    } catch (error) {
      console.error('Error fetching selected SKUs:', error);
      return [];
    }
  };

  const handleDepartmentToggle = (departmentName) => {
    const allSkusInDepartment = stockItemsByDepartment[departmentName].map(item => item.sku);
    const isDepartmentSelected = allSkusInDepartment.every(sku => selectedSkus.includes(sku));

    let updatedSelectedSkus;
    if (isDepartmentSelected) {
      updatedSelectedSkus = selectedSkus.filter(sku => !allSkusInDepartment.includes(sku));
    } else {
      updatedSelectedSkus = [...new Set([...selectedSkus, ...allSkusInDepartment])];
    }

    setSelectedSkus(updatedSelectedSkus);
  };

  const handleItemToggle = (sku) => {
    const isSelected = selectedSkus.includes(sku);
    const updatedSelectedSkus = isSelected ? selectedSkus.filter(s => s !== sku) : [...selectedSkus, sku];
    setSelectedSkus(updatedSelectedSkus);
  };

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const handleSaveStockItems = async () => {
    try {
      const access_token = sessionStorage.getItem('wms_access_token');
      await axios.post(`https://wms-api.neicha.com.au/store-stock-items/${storeId}`, { skus: selectedSkus }, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      console.log('Selected stock items saved successfully');
    } catch (error) {
      console.error('Error saving selected stock items:', error);
    }
  };

  if (loading || loadingStockItems || !storeInfo) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Paper style={{ padding: '20px' }}>
      <Typography variant="h4">Configure Store</Typography>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            label="Store ID"
            value={storeInfo.id || ''}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Store Name"
            name="storename"
            value={storeInfo.storename || ''}
            onChange={handleStoreInfoChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Store Phone"
            name="phonenumber"
            value={storeInfo.phonenumber || ''}
            onChange={handleStoreInfoChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Opening Hours</Typography>
          {storeInfo.openingHours.map((hours, index) => (
            <Grid container spacing={1} key={index} style={{ padding: '0.5em' }}>
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hours.open}
                      onChange={() => handleToggleOpen(index)}
                      color="primary"
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid item xs={2}>
                <Typography>{hours.day}</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Opening Time"
                  type="time"
                  value={hours.open ? hours.openingTime : '00:00'}
                  onChange={(e) => handleOpeningHoursChange(index, 'openingTime', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled={!hours.open}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Closing Time"
                  type="time"
                  value={hours.open ? hours.closingTime : '00:00'}
                  onChange={(e) => handleOpeningHoursChange(index, 'closingTime', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled={!hours.open}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Store Internal Extension"
            name="addressline2"
            value={storeInfo.addressline2 || ''}
            onChange={handleStoreInfoChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Click and Collect"
            name="clickAndCollect"
            select
            value={storeInfo.clickAndCollect || 'Disabled'}
            onChange={handleStoreInfoChange}
            fullWidth
          >
            <MenuItem value="Enabled">Enabled</MenuItem>
            <MenuItem value="Disabled">Disabled</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" style={{ marginTop: '40px' }}>Configure Stock Items</Typography>
      <Grid container spacing={3} style={{ marginTop: '20px', marginLeft: '0px', paddingRight: "1em" }}>
        <TableContainer style={{ overflowX: "initial" }} component={Paper}>
          <Table stickyHeader>
            {Object.keys(stockItemsByDepartment).map(departmentName => (
              <React.Fragment key={departmentName}>
                <TableRow style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                  <TableCell colSpan={2}>
                    <Typography variant="h6">{departmentName}</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stockItemsByDepartment[departmentName].every(item => selectedSkus.includes(item.sku))}
                          indeterminate={
                            stockItemsByDepartment[departmentName].some(item => selectedSkus.includes(item.sku)) &&
                            !stockItemsByDepartment[departmentName].every(item => selectedSkus.includes(item.sku))
                          }
                          onChange={() => handleDepartmentToggle(departmentName)}
                          color="primary"
                        />
                      }
                      label=""
                    />
                  </TableCell>
                </TableRow>
                {stockItemsByDepartment[departmentName].map(item => (
                  <TableRow key={item.id}>
                    <TableCell>{item.productname}</TableCell>
                    <TableCell>${formatPrice(item.productprice)}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedSkus.includes(item.sku)}
                            onChange={() => handleItemToggle(item.sku)}
                            color="primary"
                          />
                        }
                        label=""
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </Table>
        </TableContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveStockItems}
          >
            Save Stock Items
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ConfigureStore;