import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Fab, CircularProgress, Backdrop, Table, TableBody, TableCell, TableRow, TableHead, Box } from '@mui/material';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import { useAuth0 } from '@auth0/auth0-react';

const StockTake = () => {
  const [stocktakeStarted, setStocktakeStarted] = useState(JSON.parse(localStorage.getItem('stocktakeStarted')) || false);
  const [barcode, setBarcode] = useState('');
  const [products, setProducts] = useState(JSON.parse(localStorage.getItem('scannedProducts')) || []);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [stockCount, setStockCount] = useState(0);
  const [confirmStocktakeDialog, setConfirmStocktakeDialog] = useState(false);
  const [openStockCountDialog, setOpenStockCountDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productHistory, setProductHistory] = useState(null);
  const [productNotFound, setProductNotFound] = useState(false);
  const [stocktakeSuccessDialog, setStocktakeSuccessDialog] = useState(false);
  const [startTime, setStartTime] = useState(localStorage.getItem('stocktakeStartTime') ? new Date(localStorage.getItem('stocktakeStartTime')) : new Date());
  const { user } = useAuth0();
  const selectedStore = JSON.parse(localStorage.getItem('selectedStore')) || '';
  const storeId = selectedStore.id;

  useEffect(() => {
    const handleBarcodeScan = (e) => {
      if (openStockCountDialog || confirmStocktakeDialog || productNotFound || productHistory) {
        return; // Do not detect barcodes when a dialog is open
      }

      // Start detecting barcodes when stocktake has started
      if (stocktakeStarted) {
        if (e.key === 'Enter' && barcode.length > 0) {
          processBarcode(barcode);
          setBarcode('');
        } else {
          // Handle faster barcode scanning by ensuring it's a valid barcode (e.g., typically 8-13 characters)
          if (e.key.length === 1 && e.key >= '0' && e.key <= '9') {
            setBarcode((prevBarcode) => prevBarcode + e.key);
          }
        }
      }
    };

    window.addEventListener('keypress', handleBarcodeScan);

    return () => {
      window.removeEventListener('keypress', handleBarcodeScan);
    };
  }, [barcode, stocktakeStarted, openStockCountDialog, confirmStocktakeDialog, productNotFound, productHistory]);

  const startStocktake = () => {
    setStocktakeStarted(true);
    const start = new Date();
    setStartTime(start);
    localStorage.setItem('stocktakeStarted', true);
    localStorage.setItem('stocktakeStartTime', start.toISOString());
  };

  const processBarcode = async (scannedBarcode) => {
    setLoading(true);
    const access_token = sessionStorage.getItem('wms_access_token');
    const response = await fetch(`https://wms-api.neicha.com.au/product?barcode=${scannedBarcode}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
    const productData = await response.json();

    if (productData.message === 'Product not found.') {
      setProductNotFound(true);
    } else {
      setCurrentProduct(productData);
      setOpenStockCountDialog(true);
    }
    setLoading(false);
  };

  const handleStockCountChange = (newCount) => {
    setStockCount(newCount);
  };

  const handleConfirmStockCount = () => {
    if (currentProduct) {
      setProducts((prevProducts) => {
        const existingProduct = prevProducts.find((p) => p.sku === currentProduct.sku);
        if (existingProduct) {
          existingProduct.qtyCounted += stockCount;
          existingProduct.history = existingProduct.history || [];
          existingProduct.history.push({ count: stockCount, timestamp: new Date().toISOString() });
        } else {
          prevProducts.push({
            ...currentProduct,
            productname: currentProduct.productname.replace(/&amp;/g, '&'),
            qtyCounted: stockCount,
            history: [{ count: stockCount, timestamp: new Date().toISOString() }],
          });
        }
        const updatedProducts = prevProducts.filter((p) => p.qtyCounted > 0); // Remove products with 0 qty
        localStorage.setItem('scannedProducts', JSON.stringify(updatedProducts));
        return updatedProducts;
      });
      setOpenStockCountDialog(false);
      setStockCount(0); // Reset stock count
    }
  };

  const handleConfirmStocktake = async () => {
    const access_token = sessionStorage.getItem('wms_access_token');
    const stocktakeItems = products.map((product) => ({
      sku: product.sku,
      qtyCounted: product.qtyCounted,
    }));

    const requestBody = {
      createdBy: user.name,
      storeid: storeId,
      items: stocktakeItems,
    };

    await fetch('https://wms-api.neicha.com.au/stocktake', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify(requestBody),
    });

    setConfirmStocktakeDialog(false);
    setProducts([]); // Reset products after confirmation
    localStorage.removeItem('scannedProducts');
    localStorage.removeItem('stocktakeStarted');
    localStorage.removeItem('stocktakeStartTime');
    setStocktakeSuccessDialog(true); // Show success dialog
  };

  const handleSuccessDialogClose = () => {
    setStocktakeSuccessDialog(false);
    setStocktakeStarted(false);
  };

  const handleProductClick = (product) => {
    setProductHistory(product.history);
  };

  const handleDeleteScan = (product, index) => {
    const confirmed = window.confirm('Are you sure you want to delete this scan?');
    if (confirmed) {
        setProducts((prevProducts) => {
            const updatedProducts = prevProducts
                .map((p) => {
                    if (p.sku === product.sku) {
                        const updatedHistory = p.history.filter((_, i) => i !== index);
                        const updatedQtyCounted = updatedHistory.reduce((sum, h) => sum + h.count, 0);
                        return { ...p, qtyCounted: updatedQtyCounted, history: updatedHistory };
                    }
                    return p;
                })
                .filter((p) => p.qtyCounted > 0); // Remove products with 0 qty
            localStorage.setItem('scannedProducts', JSON.stringify(updatedProducts));
            return updatedProducts;
        });
        setProductHistory(null); // Close the dialog after deleting a scan
    }
  };

  const renderNumberPad = () => {
    const handleNumberClick = (number) => {
      setStockCount((prevCount) => parseInt(`${prevCount}${number}`, 10));
    };

    const handleClear = () => {
      setStockCount(0);
    };

    return (
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
          <Grid item xs={4} key={number}>
            <Button variant="contained" fullWidth onClick={() => handleNumberClick(number)} style={{ padding: '20px', fontSize: '1.5rem' }}>
              {number}
            </Button>
          </Grid>
        ))}
        <Grid item xs={8}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleClear} style={{ padding: '20px', fontSize: '1.5rem' }}>
            CLEAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  const formatDateTime = (date) => {
    return date.toLocaleString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start" // Align items to the left
      justifyContent={stocktakeStarted ? 'flex-start' : 'center'}
      spacing={2}
      style={{ paddingBottom: '56px', paddingTop: '64px', minHeight: 'calc(100vh - 56px)' }} // Account for app bar
    >
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress sx={{ color: '#9ba03b' }} />
      </Backdrop>

      <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
        <Typography style={{ marginTop: "15px" }} variant="h4">Stock Take</Typography>
        {stocktakeStarted && (
          <Typography variant="body1">Time Started: {formatDateTime(startTime)}</Typography>
        )}
      </Grid>

      {!stocktakeStarted && (
        <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
          <Typography variant="h6">Would you like to start a stocktake?</Typography>
          <Button variant="contained" color="primary" onClick={startStocktake} style={{ marginTop: '20px' }}>
            Start Stocktake
          </Button>
        </Grid>
      )}

      {stocktakeStarted && (
        <>
          {products.length === 0 && (
            <Grid item xs={12} style={{ textAlign: 'center', width: '100%', marginTop: 'auto', marginBottom: 'auto' }}>
              <GppMaybeIcon style={{ fontSize: '4rem', color: 'gray' }} />
              <Typography variant="h6">No Items have been counted</Typography>
              <Typography variant="body1">Please scan a barcode to start counting</Typography>
            </Grid>
          )}
          <div style={{ marginLeft: "2em", width: "85%", marginTop: "1em" }}>
            {products.reduce((acc, product, index) => {
              const lastDepartment = acc.length > 0 ? acc[acc.length - 1].departmentname : null;
              if (product.departmentname !== lastDepartment) {
                acc.push({ departmentname: product.departmentname, items: [product] });
              } else {
                acc[acc.length - 1].items.push(product);
              }
              return acc;
            }, []).map((department, departmentIndex) => (
              <React.Fragment key={department.departmentname}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{ width: '100%', marginTop: departmentIndex !== 0 ? '1em' : '0' }}
                  >
                    {department.departmentname}
                  </Typography>
                </Grid>
                {department.items.map((product) => (
                  <Grid item xs={12} key={product.sku}>
                    <Card style={{ width: '100%' }} onClick={() => handleProductClick(product)}>
                      <CardContent>
                        <Typography variant="h6">{product.productname}</Typography>
                        <Typography variant="body1">Counted: {product.qtyCounted}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </div>

          {/* Scan History Dialog */}
          {productHistory && (
            <Dialog open={Boolean(productHistory)} onClose={() => setProductHistory(null)} fullScreen>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px', color: 'white' }}>
                <Typography variant="h6">Count History: {currentProduct?.productname}</Typography>
              </Box>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productHistory.map((entry, index) => {
                      const date = new Date(entry.timestamp);
                      return (
                        <TableRow key={index}>
                          <TableCell>{date.toLocaleDateString()}</TableCell>
                          <TableCell>{date.toLocaleTimeString()}</TableCell>
                          <TableCell>{entry.count}</TableCell>
                          <TableCell>
                            <Button variant="contained" color="secondary" onClick={() => handleDeleteScan(currentProduct, index)}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setProductHistory(null)}
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ padding: '16px', fontSize: '1.25rem', backgroundColor: '#9ba03b', color: 'white' }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* Product Not Found Dialog */}
          <Dialog open={productNotFound} onClose={() => setProductNotFound(false)} fullScreen>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px', color: 'white' }}>
              <Typography variant="h6">Product Not Found</Typography>
            </Box>
            <DialogContent>
              <Typography variant="body1" align="center" gutterBottom>
                This Product Could not be found, please write the following information down on a paper for investigation:
              </Typography>
              <Typography variant="h6" align="center">{barcode}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setProductNotFound(false)}
                color="primary"
                variant="contained"
                fullWidth
                style={{ padding: '16px', fontSize: '1.25rem', backgroundColor: '#9ba03b', color: 'white' }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {products.length > 0 && (
            <Fab
              color="primary"
              variant="extended"
              style={{
                color: 'white',
                position: 'fixed',
                bottom: '80px',
                right: 0,
                width: '90%',
                padding: '2em',
                marginRight: '1.5em',
                backgroundColor: 'black',
              }}
              onClick={() => setConfirmStocktakeDialog(true)}
            >
              Save Stocktake
            </Fab>
          )}

          {/* Stock Count Dialog */}
          <Dialog open={openStockCountDialog} onClose={() => setOpenStockCountDialog(false)} fullWidth maxWidth="xs" fullScreen>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px', color: 'white' }}>
              <Typography variant="h6">Count: {currentProduct?.productname.replace(/&amp;/g, '&')}</Typography>
            </Box>
            <DialogContent>
              <Typography variant="h5" align="center">{stockCount}</Typography>
              {renderNumberPad()}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmStockCount}
                color="primary"
                variant="contained"
                fullWidth
                style={{
                  padding: '16px',
                  fontSize: '1.25rem',
                  backgroundColor: stockCount === 0 ? 'red' : '#9ba03b',
                  color: 'white',
                }}
              >
                {stockCount === 0 ? 'Cancel Count' : 'Confirm Count'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirm Stocktake Dialog */}
          <Dialog open={confirmStocktakeDialog} onClose={() => setConfirmStocktakeDialog(false)}>
            <DialogTitle sx={{ backgroundColor: '#9ba03b', color: 'white' }}>Are you sure you want to finalize this stocktake?</DialogTitle>
            <DialogActions>
              <Button
                onClick={handleConfirmStocktake}
                color="primary"
                variant="contained"
                style={{ backgroundColor: '#9ba03b', color: 'white' }}
              >
                Yes
              </Button>
              <Button
                onClick={() => setConfirmStocktakeDialog(false)}
                color="secondary"
                variant="contained"
                style={{ backgroundColor: 'lightgrey', color: 'black' }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

          {/* Stocktake Success Dialog */}
          <Dialog open={stocktakeSuccessDialog} onClose={handleSuccessDialogClose} fullScreen>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px', color: 'white' }}>
              <Typography variant="h6">Stock Take Completed</Typography>
            </Box>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <CheckCircleIcon style={{ fontSize: '4rem', color: 'green' }} />
              <br />
              <Typography variant="h6" align="center">Stock Take was Created</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSuccessDialogClose}
                color="primary"
                variant="contained"
                fullWidth
                style={{ padding: '16px', fontSize: '1.25rem', backgroundColor: '#9ba03b', color: 'white' }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <MobileBottomNav /> {/* Add the bottom navigation bar */}
    </Grid>
  );
};

export default StockTake;