import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component

const MobileHome = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" alignItems="center" spacing={2} style={{ paddingBottom: '56px' }}> {/* paddingBottom to avoid content being covered by bottom nav */}
      <Grid item>
        <Typography variant="h4">Mobile Home Screen</Typography>
      </Grid>
      {/* Other UI components for the home screen */}
      <MobileBottomNav /> {/* Add the bottom navigation bar */}
    </Grid>
  );
};

export default MobileHome;
