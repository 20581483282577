import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './auth_config.json';

const AuthWrapper = ({ children }) => {
  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: authConfig.audience,
        scope: authConfig.scope  // Ensure the scope is set
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthWrapper;
