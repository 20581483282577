import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Grid, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, CircularProgress } from '@mui/material';
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';

const ClickCollectOrders = ({ stores }) => {
  const navigate = useNavigate();
  const [store, setStore] = useState(() => {
    try {
      const storedValue = localStorage.getItem('selectedStore');
      return storedValue ? JSON.parse(storedValue).storename : '';
    } catch (error) {
      return '';
    }
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filter out "Warehouse" and add "All" manually
  const filteredStores = [{ id: 'all', storename: 'All' }, ...stores.filter(s => s.storename !== 'Warehouse')];

  const translateOrderStatus = (status) => {
    switch (status) {
      case 'prod-scheduled':
        return 'Awaiting Production';
      case 'pending-fulfilment':
        return 'Not Actioned Yet';
      case 'ready-to-collect':
        return 'Ready to Collect';
      case 'waiting-for-store-transfer':
        return 'Pending Transfer to Store';
      case 'pickup':
        return 'Awaiting Action';
      case 'packed':
        return 'Ready to Collect';
      case 'click-collect-esc':
        return 'Escalated to Warehouse';
      case 'store-transfer':
        return 'Awaiting Store Transfer';
      case 'ready-collect':
        return 'Ready to Collect';
      default:
        return status;
    }
  };  

  useEffect(() => {
    if (store) {
      setLoading(true);
      const fetchOrders = async () => {
        try {
          const token = sessionStorage.getItem('pfresh_main_jwt_token'); // Get the token from sessionStorage
          const response = await axios.get(
            `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${encodeURIComponent(store === 'Warehouse' ? 'All' : store)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Add the token to the Authorization header
              },
            }
          );
          setOrders(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching orders:', error);
          setLoading(false);
        }
      };
  
      fetchOrders();
    }
  }, [store]);  

  const handleStoreChange = (event) => {
    let selectedStore = event.target.value;
    
    // Set store to "All" if "Warehouse" is selected
    if (selectedStore === 'Warehouse') {
      selectedStore = 'All';
    }

    setStore(selectedStore);
    const storeObject = stores.find(s => s.storename === selectedStore) || { storename: 'All' };
    localStorage.setItem('selectedStore', JSON.stringify(storeObject));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={9}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            value={store}
            onChange={handleStoreChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Select Store</MenuItem>
            {filteredStores.map((store) => (
              <MenuItem key={store.id} value={store.storename}>
                {store.storename}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Store Location</TableCell>
                <TableCell>Time Since Order</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No Click and Collects at this time.
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((order) => (
                  <TableRow key={order.order_id}>
                    <TableCell>{order.order_id}</TableCell>
                    <TableCell>{`${order.first_name} ${order.last_name}`}</TableCell>
                    <TableCell>{order.order_date}</TableCell>
                    <TableCell>{translateOrderStatus(order.current_status)}</TableCell>
                    <TableCell>{order.store_location || ''}</TableCell>
                    <TableCell>
                      {formatDistanceToNow(parseISO(order.order_date), { addSuffix: true })}
                      <br />
                      <small>
                        {order.order_notes.length > 0
                          ? formatDistanceToNow(parseISO(order.order_notes[0].date), { addSuffix: true })
                          : 'No updates'}
                      </small>
                    </TableCell>
                    <TableCell>
                      {order.order_items.map((item, index) => (
                        <div key={index}>
                          {item.title} - {item.qty}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/view-click-collect-order/${order.order_id}`, { state: { order } })}
                      >
                        View Order
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default ClickCollectOrders;