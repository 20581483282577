import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  TextField,
  Tooltip,
  CircularProgress,
  IconButton,
  Dialog,
  Box,
  Skeleton,
  Pagination,
} from "@mui/material";
import { CheckCircle, Cancel, Close as CloseIcon } from "@mui/icons-material";

const BulkOrders = () => {
  const [bulkOrders, setBulkOrders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const token = sessionStorage.getItem("pfresh_bulk_jwt_token");

  const fetchOrders = async (searchTerm = "") => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://order.petfresh.com.au/wp-json/wc/v3/search-orders/?search=${searchTerm}&page=${page}&per_page=15`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setBulkOrders(data.orders);
      setTotalOrders(data.total_orders);
      setTotalPages(Math.ceil(data.total_orders / 15));
    } catch (error) {
      console.error("Error fetching bulk orders:", error);
    }
    setLoading(false);
  };

  // Run search on component load with no search input
  useEffect(() => {
    fetchOrders();
  }, [page]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchInput !== searchTerm) {
      setSearchTerm(searchInput);
      fetchOrders(searchInput);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  const statusTranslate = (status) => {
    switch (status) {
      case "prod-scheduled":
        return "Awaiting Production";
      case "pending-fulfilment":
        return "Not Actioned Yet";
      case "ready-to-collect":
        return "Ready to Collect";
      case "waiting-for-store-transfer":
        return "Pending Transfer to Store";
      case "pickup":
        return "Awaiting Action";
      case "packed":
        return "Ready to Collect";
      case "click-collect-esc":
        return "Escalated to Warehouse";
      case "store-transfer":
        return "Awaiting Store Transfer";
      case "ready-collect":
        return "Ready to Collect";
      default:
        return status;
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <Typography variant="h4">Bulk Orders</Typography>
        <TextField
          label="Search for orders by Name, Email, Phone"
          variant="outlined"
          value={searchInput}
          autoFocus
          onChange={handleSearchChange}
          onBlur={handleSearchSubmit}
          onKeyPress={handleSearchKeyPress}
          style={{ width: "60%" }}
        />
      </Grid>

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "300px" }}
        >
          <CircularProgress />
        </Grid>
      ) : bulkOrders.length === 0 ? (
        <Typography variant="h6">No Bulk Orders Found</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order #</TableCell>
                  <TableCell>Placed Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Collection Store</TableCell>
                  <TableCell>Placed By</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Scheduled</TableCell>
                  <TableCell>Items</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkOrders.map((order) => (
                  <TableRow key={order.orderId}>
                    <TableCell>
                      <Tooltip title="Click to view order details">
                        <Typography
                          component="a"
                          style={{
                            color: "#9ba03b",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => handleOrderClick(order)}
                        >
                          #{order.orderId}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{order.dateFormattedAWST}</TableCell>
                    <TableCell>{`${order.firstName} ${order.lastName}`}</TableCell>
                    <TableCell>{order.collectionStore}</TableCell>
                    <TableCell>{order.placedBy}</TableCell>
                    <TableCell>{order.orderPaymentMethod}</TableCell>
                    <TableCell>{statusTranslate(order.status)}</TableCell>
                    <TableCell>
                      {order.scheduled ? (
                        <Tooltip
                          title={`Scheduled Order ID: ${order.scheduleOrderID}`}
                        >
                          <Typography
                            component="a"
                            href={`https://wms.neicha.com.au/bulk-schedules/${order.scheduleOrderID}`}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <CheckCircle
                              sx={{ color: "green", fontSize: "1rem" }}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Cancel sx={{ color: "red", fontSize: "1rem" }} />
                      )}
                    </TableCell>
                    <TableCell>
                      {order.items.map((item, index) => (
                        <div key={index}>
                          {item.productName} x{item.qty}{" "}
                          {item.metadata.Status ? (
                            <Tooltip title={item.metadata.Status}>
                              <CheckCircle
                                sx={{ color: "green", fontSize: "1rem" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Has not been made">
                              <Cancel sx={{ color: "red", fontSize: "1rem" }} />
                            </Tooltip>
                          )}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Typography variant="body1">
              Page {page} of {totalPages} (Total Orders: {totalOrders})
            </Typography>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
          <br />
        </>
      )}

      {selectedOrder && (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#9ba03b",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              {loading && !selectedOrder ? (
                <Skeleton width={200} />
              ) : (
                `Bulk Order #${selectedOrder.orderId}`
              )}
            </Typography>
            <IconButton onClick={handleCloseDialog} style={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={{margin: "1em"}} sx={{ padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2} alignItems="flex-start">
              {[
                {
                  label: "Customer",
                  value: `${selectedOrder.firstName} ${selectedOrder.lastName}`,
                },
                { label: "Order Date", value: selectedOrder.dateFormattedAWST },
                { label: "Mobile", value: selectedOrder.mobile },
                { label: "Email", value: selectedOrder.email },
                {
                  label: "Collection Store",
                  value: selectedOrder.collectionStore,
                },
                { label: "Packaging Type", value: selectedOrder.packagingType },
                { label: "Order Total", value: `$${selectedOrder.orderTotal}` },                
                {
                  label: "Payment Method",
                  value: selectedOrder.orderPaymentMethod,
                },
                {
                  label: "Scheduled Order",
                  value: selectedOrder.scheduled ? (
                    <>
                      <CheckCircle sx={{ color: "green", fontSize: "1rem" }} />{" "}
                      <Typography
                        component="a"
                        href={`https://wms.neicha.com.au/bulk-schedules/${selectedOrder.scheduleOrderID}`}
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        View Schedule
                      </Typography>
                    </>
                  ) : (
                    <Cancel sx={{ color: "red", fontSize: "1rem" }} />
                  ),
                },
              ].map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    backgroundColor: "#fafafa",
                    borderRadius: "3px",
                    padding: "10px",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", fontWeight: 600 }}
                  >
                    {item.label}
                  </Typography>
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <TableContainer
            component={Paper}
            style={{ marginTop: "16px", margin: "10px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Qty Ordered</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOrder.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {item.metadata.Status ? (
                        <Tooltip title={item.metadata.Status}>
                          <CheckCircle
                            sx={{ color: "green", fontSize: "1rem" }}
                          />{" "}
                          {item.metadata.Status}
                        </Tooltip>
                      ) : (
                        <Tooltip title="Has not been made">
                          <Cancel sx={{ color: "red", fontSize: "1rem" }} /> Not
                          yet produced
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>
      )}
    </div>
  );
};

export default BulkOrders;
