import React, { useState } from "react";
import GridLayout from "react-grid-layout";
import { Box, Button, Dialog, DialogContent, Typography, Grid, IconButton, TextField, MenuItem, Select } from "@mui/material";
import BrushIcon from "@mui/icons-material/Brush";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// Label sizes and configurations
const labelOptions = [
  { id: "AV120LSR21", name: "21 Labels", code: "AV120LSR21", labelsPerSheet: 21, dimensions: "63.5 x 38.1 mm", width: 63.5, height: 38.1 },
  { id: "AV120LSR16", name: "16 Labels", code: "AV120LSR16", labelsPerSheet: 16, dimensions: "99.1 x 34 mm", width: 99.1, height: 34 },
  { id: "AV120LSR24", name: "24 Labels", code: "AV120LSR24", labelsPerSheet: 24, dimensions: "64.6 x 33.8 mm", width: 64.6, height: 33.8 },
  { id: "AV120LSR14", name: "14 Labels", code: "AV120LSR14", labelsPerSheet: 14, dimensions: "99.1 x 38.1 mm", width: 99.1, height: 38.1 },
  { id: "AV120LSR18", name: "18 Labels", code: "AV120LSR18", labelsPerSheet: 18, dimensions: "63.5 x 46.6 mm", width: 63.5, height: 46.6 },
  { id: "AV120LSR65", name: "65 Labels", code: "AV120LSR65", labelsPerSheet: 65, dimensions: "38.1 x 21.2 mm", width: 38.1, height: 21.2 },
  { id: "AV120LSR45", name: "45 Labels", code: "AV120LSR45", labelsPerSheet: 45, dimensions: "48.5 x 25.4 mm", width: 48.5, height: 25.4 },
  { id: "AV120LSR30", name: "30 Labels", code: "AV120LSR30", labelsPerSheet: 30, dimensions: "70 x 29.7 mm", width: 70, height: 29.7 },
  { id: "AV120LSR33", name: "33 Labels", code: "AV120LSR33", labelsPerSheet: 33, dimensions: "48.5 x 28 mm", width: 48.5, height: 28 },
  { id: "AV120LSR12", name: "12 Labels", code: "AV120LSR12", labelsPerSheet: 12, dimensions: "99.1 x 42.3 mm", width: 99.1, height: 42.3 },
  { id: "AV959012", name: "65 Labels", code: "AV959012", labelsPerSheet: 65, dimensions: "38.1 x 21.2 mm", width: 38.1, height: 21.2 },
];

// Default layout and available elements
const availableElements = [
  { id: "Product", label: "Product" },
  { id: "Price", label: "Price" },
  { id: "SalePrice", label: "Sale Price" },
  { id: "SKU", label: "SKU" },
  { id: "Department", label: "Department" },
  { id: "Image", label: "Image" },
  { id: "Text", label: "Text" },
  { id: "Rectangle", label: "Rectangle" }
];

// Initial styles for elements
const initialStyles = {
  Product: { backgroundColor: "#fff", color: "#000", fontSize: "16px", fontWeight: "normal", textAlign: "left", verticalAlign: "top", padding: "0px", fontFamily: "Arial" },
  Price: { backgroundColor: "#f8f8f8", color: "#000", fontSize: "16px", fontWeight: "normal", textAlign: "left", verticalAlign: "top", padding: "0px", fontFamily: "Arial" },
  SalePrice: { backgroundColor: "#ffebcd", color: "#000", fontSize: "16px", fontWeight: "normal", textAlign: "left", verticalAlign: "top", padding: "0px", fontFamily: "Arial" },
};

const LabelTemplateBuilder = () => {
  const [layout, setLayout] = useState([]);
  const [styles, setStyles] = useState(initialStyles);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isStyleDialogOpen, setStyleDialogOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [elementStyles, setElementStyles] = useState({});
  const [isLabelSelected, setLabelSelected] = useState(false);

  // Open the style dialog and populate with the selected element's styles
  const handleOpenStyleDialog = (elementId) => {
    setSelectedElement(elementId);
    setElementStyles(styles[elementId] || {});
    setStyleDialogOpen(true);
  };

  // Update styles in real-time as inputs change
  const handleStyleChange = (prop, value) => {
    setElementStyles((prevStyles) => ({
      ...prevStyles,
      [prop]: value
    }));
    setStyles((prevStyles) => ({
      ...prevStyles,
      [selectedElement]: {
        ...prevStyles[selectedElement],
        [prop]: value
      }
    }));
  };

  // Add an element to the layout
  const addElementToLayout = (element) => {
    if (layout.length >= 12) {
      alert("There is no space to add this element, please remove or resize existing elements to make space.");
      return;
    }
    if (element.id !== "Image" && layout.some((el) => el.i.includes(element.id))) {
      alert(`${element.label} can only be added once.`);
      return;
    }
    const uniqueId = `${element.id}-${Date.now()}`;
    setLayout([...layout, { i: uniqueId, x: 0, y: 0, w: 2, h: 2, static: false }]);
    setStyles({ ...styles, [uniqueId]: { ...initialStyles[element.id], id: uniqueId } });
  };

  // Handle label size selection
  const handleLabelSelect = (label) => {
    setSelectedSize(label);
    setLabelSelected(true);
  };

  // Calculate the grid layout dimensions based on the selected label
  const calculateRowHeight = () => {
    if (!selectedSize) return 30;
    const gridHeight = selectedSize.height * 5;
    const rows = 12;
    return gridHeight / rows;
  };

  // Ensure elements do not exceed label boundaries
  const restrictElementToLabel = (newLayout) => {
    return newLayout.map((item) => {
      const maxW = Math.floor(selectedSize.width / calculateRowHeight());
      const maxH = Math.floor(selectedSize.height / calculateRowHeight());
      return {
        ...item,
        w: Math.min(item.w, maxW),
        h: Math.min(item.h, maxH)
      };
    });
  };

  // Delete an element from the layout
  const handleDeleteElement = () => {
    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== selectedElement));
    setStyles((prevStyles) => {
      const newStyles = { ...prevStyles };
      delete newStyles[selectedElement];
      return newStyles;
    });
    setStyleDialogOpen(false);
  };

  // Export layout to JSON
  const handleExportLayout = () => {
    const layoutWithStyles = layout.map((item) => ({
      ...item,
      styles: styles[item.i] || {},
    }));

    const layoutData = { layout: layoutWithStyles, selectedSize };
    const blob = new Blob([JSON.stringify(layoutData, null, 2)], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "label-template.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      {!isLabelSelected ? (
        <>
          <Typography variant="h6">Select Label Size</Typography>
          <Grid container spacing={2}>
            {labelOptions.map((label) => (
              <Grid item xs={3} key={label.id}>
                <Box
                  sx={{ border: "1px solid #ccc", padding: "16px", cursor: "pointer" }}
                  onClick={() => handleLabelSelect(label)}
                >
                  <img src="https://via.placeholder.com/100" alt={label.name} />
                  <Typography variant="h6">{label.name}</Typography>
                  <Typography variant="body1">Code: {label.code}</Typography>
                  <Typography variant="body2">Size: {label.dimensions}</Typography>
                  <Typography variant="body2">Labels per Sheet: {label.labelsPerSheet}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6">Elements</Typography>
              <Box sx={{ border: "1px solid black", padding: "8px" }}>
                {availableElements.map((element) => (
                  <Button
                    key={element.id}
                    variant="contained"
                    fullWidth
                    onClick={() => addElementToLayout(element)}
                    sx={{ marginBottom: "8px" }}
                  >
                    {element.label}
                  </Button>
                ))}
              </Box>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h6">Design Labels ({selectedSize.name}, {selectedSize.dimensions})</Typography>

              {/* React Grid Layout */}
              <Box
                sx={{
                  border: "1px solid black",
                  width: `${selectedSize.width * 5}px`,
                  height: `${selectedSize.height * 5}px`,
                  margin: "auto",
                  overflow: "hidden",
                  backgroundColor: "#f0f0f0",
                  padding: 0
                }}
              >
                <GridLayout
                  className="layout"
                  layout={layout}
                  cols={12}
                  rowHeight={calculateRowHeight()}
                  width={selectedSize.width * 5}
                  onLayoutChange={(newLayout) => setLayout(restrictElementToLabel(newLayout))}
                  draggableHandle=".drag-handle"
                  isResizable={true}
                  preventCollision={true}
                >
                  {layout.map((item) => (
                    <div
                      key={item.i}
                      style={styles[item.i]}
                      className="grid-item"
                    >
                      <div className="drag-handle" style={{ cursor: "move" }}>
                        {item.i.includes("Image") && styles[item.i].imageSrc && (
                          <img src={styles[item.i].imageSrc} alt="Image" style={{ width: "100%", height: "100%" }} />
                        )}
                      </div>
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => handleOpenStyleDialog(item.i)}
                      >
                        <BrushIcon />
                      </IconButton>
                    </div>
                  ))}
                </GridLayout>
              </Box>
            </Grid>
          </Grid>

          {/* Export button */}
          <Button variant="contained" sx={{ marginTop: "16px" }} onClick={handleExportLayout}>
            Export to JSON
          </Button>

          {/* Style Dialog */}
          <Dialog open={isStyleDialogOpen} onClose={() => setStyleDialogOpen(false)}>
            <DialogContent>
              <Typography variant="h6">Edit Styles for {selectedElement}</Typography>
              <TextField
                label="Background Color"
                fullWidth
                type="color"
                value={elementStyles.backgroundColor || "#ffffff"}
                onChange={(e) => handleStyleChange("backgroundColor", e.target.value)}
                sx={{ marginBottom: "16px" }}
              />
              <TextField
                label="Text Color"
                fullWidth
                type="color"
                value={elementStyles.color || "#000000"}
                onChange={(e) => handleStyleChange("color", e.target.value)}
                sx={{ marginBottom: "16px" }}
              />
              <TextField
                label="Font Size (px)"
                fullWidth
                type="number"
                value={elementStyles.fontSize?.replace("px", "") || "16"}
                onChange={(e) => handleStyleChange("fontSize", e.target.value + "px")}
                sx={{ marginBottom: "16px" }}
              />
              <Select
                label="Font Weight"
                fullWidth
                value={elementStyles.fontWeight || "normal"}
                onChange={(e) => handleStyleChange("fontWeight", e.target.value)}
                sx={{ marginBottom: "16px" }}
              >
                <MenuItem value="normal">Normal</MenuItem>
                <MenuItem value="bold">Bold</MenuItem>
              </Select>
              <Select
                label="Font Family"
                fullWidth
                value={elementStyles.fontFamily || "Arial"}
                onChange={(e) => handleStyleChange("fontFamily", e.target.value)}
                sx={{ marginBottom: "16px" }}
              >
                <MenuItem value="Arial" style={{ fontFamily: "Arial" }}>Arial</MenuItem>
                <MenuItem value="Georgia" style={{ fontFamily: "Georgia" }}>Georgia</MenuItem>
                <MenuItem value="Times New Roman" style={{ fontFamily: "Times New Roman" }}>Times New Roman</MenuItem>
              </Select>
              <Select
                label="Text Alignment"
                fullWidth
                value={elementStyles.textAlign || "left"}
                onChange={(e) => handleStyleChange("textAlign", e.target.value)}
                sx={{ marginBottom: "16px" }}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
              <Select
                label="Vertical Alignment"
                fullWidth
                value={elementStyles.verticalAlign || "top"}
                onChange={(e) => handleStyleChange("verticalAlign", e.target.value)}
                sx={{ marginBottom: "16px" }}
              >
                <MenuItem value="top">Top</MenuItem>
                <MenuItem value="middle">Middle</MenuItem>
                <MenuItem value="end">End</MenuItem>
              </Select>
              <TextField
                label="Padding (Top, Left, Bottom, Right)"
                fullWidth
                value={elementStyles.padding || "0px"}
                onChange={(e) => handleStyleChange("padding", e.target.value)}
                sx={{ marginBottom: "16px" }}
              />

              {/* Extra Options for Images */}
              {selectedElement && selectedElement.includes("Image") && (
                <TextField
                  label="Image Link (URL)"
                  fullWidth
                  value={elementStyles.imageSrc || ""}
                  onChange={(e) => handleStyleChange("imageSrc", e.target.value)}
                  sx={{ marginBottom: "16px" }}
                />
              )}

              {/* Prefix and Suffix for specific fields */}
              {["Product", "Price", "SalePrice", "SKU", "Department"].includes(selectedElement) && (
                <>
                  <TextField
                    label="Prefix Text"
                    fullWidth
                    value={elementStyles.prefix || ""}
                    onChange={(e) => handleStyleChange("prefix", e.target.value)}
                    sx={{ marginBottom: "16px" }}
                  />
                  <TextField
                    label="Suffix Text"
                    fullWidth
                    value={elementStyles.suffix || ""}
                    onChange={(e) => handleStyleChange("suffix", e.target.value)}
                    sx={{ marginBottom: "16px" }}
                  />
                </>
              )}

              {/* Text value for Text and Rectangle */}
              {selectedElement && (selectedElement.includes("Text") || selectedElement.includes("Rectangle")) && (
                <TextField
                  label="Text Value"
                  fullWidth
                  value={elementStyles.textValue || ""}
                  onChange={(e) => handleStyleChange("textValue", e.target.value)}
                  sx={{ marginBottom: "16px" }}
                />
              )}

              {/* Delete Button */}
              <Button variant="contained" color="error" fullWidth onClick={handleDeleteElement}>
                Delete Element
              </Button>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default LabelTemplateBuilder;
