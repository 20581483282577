import React from 'react';
import { Typography } from '@mui/material';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import { Grid } from '@mui/material';

const SearchInventory = () => {
    return (
        <Grid container direction="column" alignItems="center" spacing={2} style={{ paddingBottom: '56px' }}> {/* paddingBottom to avoid content being covered by bottom nav */}
          <Grid item>
            <Typography variant="h4">Inventory Screen</Typography>
          </Grid>
          {/* Other UI components for the home screen */}
          <MobileBottomNav /> {/* Add the bottom navigation bar */}
        </Grid>
      );
    
};

export default SearchInventory;
